import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from "@angular/router";

import { EnvServiceProvider } from './_services/env.service.provider';

import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';

// import ngx-translate and the http loader
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader/';

import { BsModalService } from "ngx-bootstrap/modal";

import { NgxPaginationModule } from "ngx-pagination";

import { CategoryService, AlertService, AuthenticationService, UserCreationService, TaxAdvisorsService, SharedService, SettingsService, MobileUsersService, DecisionTreeService } from './_services/index';
import { AlertComponent, PermissionDirective, ActiveTabsDirective, ConfirmEqualValidatorDirective } from './_directives/index';
import { AuthGuard } from './_guards/index';
import { JwtInterceptor } from './_helpers/index';

import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';

import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './login/login.component';
import { CategoriesListComponent } from './categories/categories.component';
import { CreateCategoryComponent } from './categories/create/create-category.component';
import { EditCategoryComponent } from './categories/edit/edit-category.component';
import { EditSubcategoryComponent } from './categories/edit/edit-subcategory.component';
import { CreateSubcategoryComponent } from './categories/create/create-subcategory.component';
import { FooterComponent } from './footer/footer.component';
import { ActiveCategoryTabsDirective } from './_directives/active-category-tabs.directive';

import { TaxAdvisorsComponent } from './taxadvisors/taxadvisors.component';
import { SingleTaxAdvisorComponent } from './taxadvisors/single-taxadvisor/single-taxadvisor.component';

import { TabsModule, BsDropdownModule, ModalModule, BsDatepickerModule, CarouselModule } from "ngx-bootstrap";
import { CreateTaxAdvisorComponent } from './taxadvisors/create/create-taxadvisor.component';
import { CreateTaxAdvisorsManagerComponent } from './taxadvisors/create/create-taxadvisors-manager.component';
import { EditTaxAdvisorComponent } from './taxadvisors/edit/edit-taxadvisor.component';
import { EditTaxAdvisorsManagerComponent } from './taxadvisors/edit/edit-taxadvisors-manager.component';

import { SettingsComponent } from './settings/settings.component';
import { LanguageComponent } from './settings/language/language.component';
import { MobileUsersComponent } from './mobile-users/mobile-users.component';
import { SingleMobileUserComponent } from './mobile-users/single-mobile-user/single-mobile-user.component';
import { DecisionTreeComponent } from './decision-tree/decision-tree.component';
import { DecisionTreeVisualizationComponent } from './decision-tree/decision-tree-visualization/decision-tree-visualization.component';
import { NodeWrapComponent } from './decision-tree/decision-tree-visualization/node-wrap/node-wrap.component';
import { NodeComponent } from './decision-tree/decision-tree-visualization/node/node.component';
import { DecisionTreeTableComponent } from './decision-tree/decision-tree-table/decision-tree-table.component';
import { UserAttributesComponent } from './settings/user-attributes/user-attributes.component';
import { EditMobileUserComponent } from './mobile-users/edit-mobile-user/edit-mobile-user.component';
import { SingleTaxadvisorsManagerComponent } from './taxadvisors/single-taxadvisors-manager/single-taxadvisors-manager.component';
import { FilterPipe } from './pipes/filter.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { DragScrollModule } from "ngx-drag-scroll/lib";
import { AwsTunnelDirective } from './_directives/aws-tunnel.directive';
import { LazyLoadDirective } from './_directives/lazy-load.directive';
import { FaqComponent } from './settings/faq/faq.component';


// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    CategoriesListComponent,
    HeaderComponent,
    CreateCategoryComponent,
    EditCategoryComponent,
    EditSubcategoryComponent,
    AlertComponent,
    LoginComponent,
    PermissionDirective,
    CreateSubcategoryComponent,
    FooterComponent,
    ActiveTabsDirective,
    ActiveCategoryTabsDirective,
    TaxAdvisorsComponent,
    SingleTaxAdvisorComponent,
    CreateTaxAdvisorComponent,
    CreateTaxAdvisorsManagerComponent,
    EditTaxAdvisorsManagerComponent,
    EditTaxAdvisorComponent,
    ConfirmEqualValidatorDirective,
    SettingsComponent,
    LanguageComponent,
    MobileUsersComponent,
    DecisionTreeComponent,
    DecisionTreeVisualizationComponent,
    NodeWrapComponent,
    NodeComponent,
    DecisionTreeTableComponent,
    UserAttributesComponent,
    SingleMobileUserComponent,
    EditMobileUserComponent,
    SingleTaxadvisorsManagerComponent,
    FilterPipe,
    OrderByPipe,
    AwsTunnelDirective,
    LazyLoadDirective,
    FaqComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    BrowserAnimationsModule,
    LoadingBarHttpClientModule,
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    CarouselModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxPaginationModule,
    DragScrollModule
  ],
  providers: [
    EnvServiceProvider,
    CategoryService,
    AlertService,
    AuthGuard,
    AuthenticationService,
    UserCreationService,
    TaxAdvisorsService,
    SharedService,
    SettingsService,
    MobileUsersService,
    DecisionTreeService,
    BsModalService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    }
  ],
  exports: [TranslateModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
