import { Component, OnInit } from '@angular/core';
import { MobileUsersService, SettingsService } from "../../_services/index";
import { ActivatedRoute, Router } from "@angular/router";
import { NgSelectComponent } from "@ng-select/ng-select";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-edit-mobile-user',
  templateUrl: './edit-mobile-user.component.html',
  styleUrls: ['./edit-mobile-user.component.less']
})
export class EditMobileUserComponent implements OnInit {

  singleMobileUserForm: FormGroup;
  singleMobileUser;
  singleMobileUserId = this.route.snapshot.params['id'];
  attributesList;
  back_url: any = localStorage.getItem('back_url');

  constructor(
    private _mobileUsersService: MobileUsersService,
    private _SettingsService: SettingsService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private translate: TranslateService
  ) {
    this.singleMobileUserData();
  }

  ngOnInit() {

  }

  public singleMobileUserData(): void {
    this._mobileUsersService.getSingleMobileUser({
        id: this.singleMobileUserId
      }).subscribe(res => {
        console.log(res);
        this.singleMobileUser = res.users;
        this.createForm();
        this.getAttributes();
      }, error => {
        console.log(error);
      });
  }

  public getAttributes(): void {
    this._SettingsService.getAttributes()
      .subscribe(res => {
        this.attributesList = res.result;
      }, err => {
        console.log(err);
      });
  }

  public createForm(): void {
    const emailPattern = "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?";
    this.singleMobileUserForm = this.fb.group({
      name: [this.singleMobileUser.name, [Validators.required, Validators.maxLength(50)]],
      second_name: [this.singleMobileUser.second_name, [Validators.required, Validators.maxLength(50)]],
      email: [this.singleMobileUser.email, [Validators.required, Validators.pattern(emailPattern), Validators.maxLength(40)]],
      additional_info: [this.singleMobileUser.additional_information, [Validators.maxLength(500)]],
      address: [this.singleMobileUser.address, [Validators.required, Validators.maxLength(50)]],
      attributes: [this.singleMobileUser.attributes, []]
    });
  }

  public editMobileUser(): void {
    const singleUserData = this.singleMobileUserForm.value;
    singleUserData['id'] = parseInt(this.singleMobileUserId, 10);
    singleUserData['attribute_ids'] = [];
    singleUserData.attributes.forEach(obj => {
      this.attributesList.forEach(child_obj => {
        if (obj.id === child_obj.id) {
          singleUserData['attribute_ids'].push({
            id: child_obj.id,
            type: child_obj.type
          });
        }
      });
    });
    console.log(singleUserData);
    this._mobileUsersService.updateSingleMobileUser(singleUserData)
      .subscribe(res => {
        this.router.navigate([`mobile-users/${this.singleMobileUserId}`]);
      }, err => {
        console.log(err);
      });
  }


  closeSelect(select: NgSelectComponent) {
    select.close();
  }
  openSelect(select: NgSelectComponent) {
    select.open();
  }
  clearAll() {
    let translated_msg;
    this.translate.get('Alert.Are_you_sure_question')
      .subscribe((res: Object) => {
        translated_msg = res;
        let q = confirm(translated_msg);
        if (q) {
          this.singleMobileUserForm.value['attributes'] = [];
          this.singleMobileUserForm.controls['attributes'].setValue(this.singleMobileUserForm.value['attributes']);
        }
      });
  }
}
