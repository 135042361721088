<div class="mobile-users-page-tpl container">
  <div class="row">
    <div class="main-content col-12">
      <div class="top-bar">
        <div>{{ 'Mobile_users.Page_title' | translate }}</div>
      </div>
      <div class="datatable-wrapper">
        <div class="filters" >
          <div class="search">
            <label class="search-label" for="pConfig1.search">
              <i class="fas fa-search"></i>
            </label>
            <input [placeholder]="'Shared.Type_to_filter' | translate" [(ngModel)]="pConfig1.search" id="pConfig1.search" name='search' class="doInput">
          </div>
          <div class="itemsPerPage">
            <label>{{'Shared.Items_per_page' | translate}}:</label>
            <div class="perPageIndicators">
              <button [ngClass]="{'active': pConfig1.itemsPerPage === 1}" (click)="pConfig1.itemsPerPage = 1; pConfig1.currentPage = 0">1</button>
              <button [ngClass]="{'active': pConfig1.itemsPerPage === 5}" (click)="pConfig1.itemsPerPage = 5; pConfig1.currentPage = 0">5</button>
              <button [ngClass]="{'active': pConfig1.itemsPerPage === 10}" (click)="pConfig1.itemsPerPage = 10; pConfig1.currentPage = 0">10</button>
              <button [ngClass]="{'active': pConfig1.itemsPerPage === 20}" (click)="pConfig1.itemsPerPage = 20; pConfig1.currentPage = 0">20</button>
            </div>
          </div>
        </div>
        <div class="table-wrapper">
          <table class="table">
            <thead>
            <tr>
              <th class="name" (click)="sortBy1('name')">
                <span>{{'Mobile_users.User_name' | translate}}</span>
                <span class="sort_icons" [hidden]="pConfig1.sortField != 'name'">
                        <i class="fas fa-sort-down" [class.sort_active]="pConfig1.sortField == 'name' && pConfig1.reverseOrder == true"></i>
                        <i class="fas fa-sort-up" [class.sort_active]="pConfig1.sortField == 'name' && pConfig1.reverseOrder == false"></i>
                    </span>
              </th>
              <th class="second_name" (click)="sortBy1('second_name')">
                <span>{{'Mobile_users.User_second_name' | translate}}</span>
                <span class="sort_icons" [hidden]="pConfig1.sortField != 'second_name'">
                        <i class="fas fa-sort-down" [class.sort_active]="pConfig1.sortField == 'second_name' && pConfig1.reverseOrder == true"></i>
                        <i class="fas fa-sort-up" [class.sort_active]="pConfig1.sortField == 'second_name' && pConfig1.reverseOrder == false"></i>
                    </span>
              </th>
              <th class="phone_number" (click)="sortBy1('phone')">
                <span>{{'Mobile_users.User_phone_number' | translate}}</span>
                <span class="sort_icons" [hidden]="pConfig1.sortField != 'phone'">
                        <i class="fas fa-sort-down" [class.sort_active]="pConfig1.sortField == 'phone' && pConfig1.reverseOrder == true"></i>
                        <i class="fas fa-sort-up" [class.sort_active]="pConfig1.sortField == 'phone' && pConfig1.reverseOrder == false"></i>
                    </span>
              </th>
              <th class="user_work_manager" (click)="sortBy1('work_manager.name')" *permission="['operator']">
                <span>{{'Mobile_users.User_working_manager' | translate}}</span>
                <span class="sort_icons" [hidden]="pConfig1.sortField != 'work_manager.name'">
                        <i class="fas fa-sort-down" [class.sort_active]="pConfig1.sortField == 'work_manager.name' && pConfig1.reverseOrder == true"></i>
                        <i class="fas fa-sort-up" [class.sort_active]="pConfig1.sortField == 'work_manager.name' && pConfig1.reverseOrder == false"></i>
                    </span>
              </th>
              <th class="user_work_manager_status" (click)="sortBy1('work_manager.status_name')">
                <span>{{'Mobile_users.User_working_manager_status' | translate}}</span>
                <span class="sort_icons" [hidden]="pConfig1.sortField != 'work_manager.status_name'">
                        <i class="fas fa-sort-down" [class.sort_active]="pConfig1.sortField == 'work_manager.status_name' && pConfig1.reverseOrder == true"></i>
                        <i class="fas fa-sort-up" [class.sort_active]="pConfig1.sortField == 'work_manager.status_name' && pConfig1.reverseOrder == false"></i>
                    </span>
              </th>
              <th class="user_status" (click)="sortBy1('is_active')">
                <span>{{'Mobile_users.User_status' | translate}}</span>
                <span class="sort_icons" [hidden]="pConfig1.sortField != 'is_active'">
                        <i class="fas fa-sort-down" [class.sort_active]="pConfig1.sortField == 'is_active' && pConfig1.reverseOrder == true"></i>
                        <i class="fas fa-sort-up" [class.sort_active]="pConfig1.sortField == 'is_active' && pConfig1.reverseOrder == false"></i>
                    </span>
              </th>
            </tr>
            </thead>
            <tbody *ngIf="mobileUsers">
            <tr (click)="userDetailsRedirect(i)" class="hover_rows" *ngFor="let i of mobileUsers | filter: pConfig1.search: 'name,second_name,phone,work_manager.name,work_manager.status_name' | orderBy: pConfig1.sortField:pConfig1.reverseOrder | paginate: {
                    id: 'mobile_users_pagination',
                    itemsPerPage: pConfig1.itemsPerPage,
                    currentPage: pConfig1.currentPage
                   }">
              <td>
                {{i.name}}
              </td>
              <td>
                {{i.second_name}}
              </td>
              <td>
                {{i.phone}}
              </td>
              <td *permission="['operator']">
                {{i.work_manager.name}}
              </td>
              <td>
                {{i.work_manager.status_name}}
              </td>
              <td class="status_td">
                <i [class.active]="i.is_active === 1" class="fas fa-circle"></i>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="table-footer" *ngIf="mobileUsers">
          <div class="items-total-wrapper">
                <span translate [translateParams]="{ total_items: mobileUsers.length }">
                  Shared.Total_items_in_table
                </span>
          </div>
          <pagination-controls  id="mobile_users_pagination"
                                class="table-pagination"
                                (pageChange)="pageChange1($event)"
                                maxSize="5"
                                directionLinks="false"
                                autoHide="true"
                                responsive="false">
          </pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>
