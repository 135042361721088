import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class SharedService {
  
  changeLanguageEvent: EventEmitter<any> = new EventEmitter();
  
  constructor() { }

}
