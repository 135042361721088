import {Component, OnInit, ViewEncapsulation, ViewChild, AfterViewInit, TemplateRef, Output, EventEmitter, ChangeDetectorRef, AfterViewChecked} from '@angular/core';
import { AlertService } from "../../_services/alert.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MobileUsersService, TaxAdvisorsService, SharedService, SettingsService, AwsHandlerService } from "../../_services/index";
import { Observable } from "rxjs";
import { TabDirective, TabsetComponent, BsModalService, ModalDirective } from 'ngx-bootstrap';
import { TranslateService } from "@ngx-translate/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import * as moment_ from 'moment';


@Component({
  selector: 'app-single-mobile-user',
  templateUrl: './single-mobile-user.component.html',
  styleUrls: ['./single-mobile-user.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class SingleMobileUserComponent implements OnInit, AfterViewInit, AfterViewChecked  {

  showLoading = true;

  // @Output() eventt = new EventEmitter<any>();
  @ViewChild(ModalDirective) modal: ModalDirective;
  @ViewChild('staticTabs') staticTabs: TabsetComponent;
  modalRef: BsModalRef;
  singleMobileUserId = this.route.snapshot.params['id'];
  singleMobileUser;
  singleManagerTimeLogged;
  totalTimeLoggedForUser;
  taxAdvisorManagers: any[];
  tab = 1;
  rejectDocumentForm: FormGroup;
  assignManagerForm: FormGroup;
  workStatusForm: FormGroup;
  logWorkTimeForm: FormGroup;
  editWorkTimeForm: FormGroup;
  work_statuses = [{
    id: 0,
    name: '',
    translated_name: 'Shared.Mobile_user_work_status_0'
  }, {
    id: 1,
    name: '',
    translated_name: 'Shared.Mobile_user_work_status_1'
  }, {
    id: 2,
    name: '',
    translated_name: 'Shared.Mobile_user_work_status_2'
  }, {
    id: 3,
    name: '',
    translated_name: 'Shared.Mobile_user_work_status_3'
  }, {
    id: 4,
    name: '',
    translated_name: 'Shared.Mobile_user_work_status_4'
  }];
  
  userDocumentsSlider = {
    images: [],
    activeSlideIndex: 0
  };

  imageToPreview: string;

  lang: any = {
    active: localStorage.getItem('lang')
  };

  pConfig1 = {
    itemsPerPage: 5,
    currentPage: 1,
    sortField: '',
    reverseOrder: false,
    search: '',
    search_strings: ''
  };

  USER: any;

  getUserData(): void {
    this.USER = JSON.parse(localStorage.getItem('currentUser'));
  }

  constructor(
    private _alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private _mobileUsersService: MobileUsersService,
    private _taxAdvisorsService: TaxAdvisorsService,
    private translate: TranslateService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private _sharedService: SharedService,
    private _SettingsService: SettingsService,
    private awsService: AwsHandlerService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.singleMobileUserData(true);
    this._sharedService.changeLanguageEvent
      .subscribe((res) => {
        this.lang.active = res;
        this.changeStatusLang();
        this.categoriesLangHandler();
      });
  }

  ngAfterViewInit() {}
  
  ngAfterViewChecked() {
    /*console.log('qweqweqwe');
    this.cdRef.detectChanges();*/
  }

  sortBy1 = function(sortField) {
    this.pConfig1['reverseOrder'] = (this.pConfig1['sortField'] === sortField) ? !this.pConfig1['reverseOrder'] : false;
    this.pConfig1['sortField'] = sortField;
  };
  pageChange1(event: any): void {
    this.pConfig1['currentPage'] = event;
  }
  tabHandler(pageId: number): void {
    this.tab = pageId;
    if (pageId === 3) {}
  }

  changeStatusLang(): void {
    this.translate.get(['Shared.Mobile_user_work_status_0', 'Shared.Mobile_user_work_status_1', 'Shared.Mobile_user_work_status_2', 'Shared.Mobile_user_work_status_3', 'Shared.Mobile_user_work_status_4'])
      .subscribe(response => {
        Object.entries(response).forEach(
          ([key, value]) => {
            this.work_statuses.forEach(obj => {
              if (obj.translated_name === key) {
                obj['name'] = value.toString();
              }
              if (this.singleMobileUser) {
                if (this.singleMobileUser.work_manager.status === obj.id) {
                  this.singleMobileUser.work_manager.status_name = obj.name;
                }
              }
            });
          }
        );
        this.work_statuses = [...this.work_statuses];
      });
  }
  public categoriesLangHandler(): void {
    this.singleMobileUser['relevantSubcategories'].forEach(i => {
      if (this.lang.active === 'en') {
        i['title_to_show'] = i['title'];
      } else {
        for (let key of Object.keys(i['title_translation'])) {
          if (key.toLowerCase() === this.lang.active) {
            if (i['title_translation'][key] !== null) {
              i['title_to_show'] = i['title_translation'][key];
            } else {
              i['title_to_show'] = i['title'];
            }
          }
        }
      }
    });
    console.log(this.singleMobileUser['relevantSubcategories']);
  }
  

  openLogTimeModal(template: TemplateRef<any>) {
    if (!this.singleMobileUser.work_manager.status) {
      this._alertService.error('Mobile_users.You_should_start_working_with_user_before_logging_time');
    } else {
      this.logWorkTimeFormInit();
      this.modalRef = this.modalService.show(
        template,
        Object.assign( { 'class': 'logTimeModal' })
      );
    }
  }
  openEditLogTimeModal(template: TemplateRef<any>, i) {
    this.editWorkTimeFormInit(i);
    this.modalRef = this.modalService.show(
      template,
      Object.assign({ 'class': 'logTimeModal' })
    );
  }
  openImageLighbox(template: TemplateRef<any>, link, imageArray, index) {
    this.userDocumentsSlider = {
      images: [],
      activeSlideIndex: 0
    };
    this.imageToPreview = link;
    this.userDocumentsSlider['images'] = imageArray;
    setTimeout(res => {
      this.userDocumentsSlider['activeSlideIndex'] = index;
    }, 0);
    this.modalRef = this.modalService.show(
      template,
      Object.assign( { 'class': 'imageLightbox' })
    );
  }
  openRejectDocumentModal(template: TemplateRef<any>, i) {
    this.rejectDocumentFormInit(i);
    this.modalRef = this.modalService.show(
      template,
      Object.assign({ 'class': 'logTimeModal' })
    );
  }

  public rejectDocumentFormInit(i): void {
    this.rejectDocumentForm = this.fb.group({
      reject_comment: [ '', [Validators.required] ],
      document_id: [i.id, []]
    });
  }
  public assignManagerFormInit(): void {
    this.assignManagerForm = this.fb.group({
      taxAdvisorManager: [ this.singleMobileUser.work_manager.id, [] ]
    });
  }
  public workStatusFormInit(): void {
    this.workStatusForm = this.fb.group({
      workingStatus: [ this.singleMobileUser.work_manager.status, [] ]
    });
  }
  public logWorkTimeFormInit(): void {
    this.logWorkTimeForm = this.fb.group({
      time: [ '', [Validators.required, Validators.min(1)] ],
      date: [ new Date(), [Validators.required] ],
      comment: [ '', [] ],
    });
  }
  public editWorkTimeFormInit(i): void {
    this.editWorkTimeForm = this.fb.group({
      time: [ i.log_time, [Validators.required, Validators.min(1)] ],
      date: [ i.log_date || new Date(), [Validators.required] ],
      comment: [ i.comment, [] ],
      id: [ i.id, [] ]
    });
  }

  getSingleMobileUserData(): Observable<any> {
    return this._mobileUsersService.getSingleMobileUser({
      id: this.singleMobileUserId
    });
  }

  public getAttributes(): void {
    this.showLoading = true;
    this._SettingsService.getAttributes()
      .subscribe(res => {
        let neededDocuments = [];
        
        
        this.singleMobileUser['attributes'].forEach(obj => {
          res.result.forEach(child_obj => {
            if (obj.description === child_obj.description) {
              child_obj.sub_categories.forEach(deep => {
                deep['docs'] = [];
                neededDocuments.push(deep);
              });
            }
          });
        });
        let uniq = {};
        neededDocuments = neededDocuments.filter(obj => {
          return !uniq[obj.id] && (uniq[obj.id] = true);
        });
        this.singleMobileUser['relevantSubcategories'] = neededDocuments;
        this.getSubmittedUserDocuments();
        this.categoriesLangHandler();
        /*console.log(this.singleMobileUser);
        console.log(res.result);*/
        
      }, err => {
        console.log(err);
      });
  }
  public getSubmittedUserDocuments(): void {
    console.log('DOCUMENTS');
    this._mobileUsersService.getSubmittedUserDocuments({
      user_id: this.singleMobileUserId
    }).subscribe(res => {
      res.result.forEach(obj => {
        this.singleMobileUser['relevantSubcategories'].forEach(child_obj => {
          if (obj.sub_category_id === child_obj.id) {
            child_obj['docs'].push(obj);
          }
        });
      });
      if (res.result.length) {
        this.awsService.handleAWSimages(this.singleMobileUser)
          .subscribe(res111 => {
            this.singleMobileUser = res111;
            this.showLoading = false;
          }, error2 => {});
      }
      this.showLoading = false;
    }, err => {
      console.log(err);
    });
  }

  public singleMobileUserData(reloadDocs): void {
    this.getSingleMobileUserData()
      .subscribe(res => {
        (res.users.work_manager.id) ? res.users['is_manager_assigned'] = true : res.users['is_manager_assigned'] = false;
        // console.log(this.work_statuses);
        this.work_statuses.forEach(obj => {
          if (obj.id === res.users.work_manager.status) {
            res.users.work_manager['status_name'] = obj.name;
            res.users.work_manager['change_status'] = false;
          }
        });
        this.singleMobileUser = res.users;
        localStorage.setItem('back_url', this.router.url);
        this.getUserData();
        this.getManagerTimeLogByUser();
        if (this.USER.role === 'operator') {
          this.pConfig1['search_strings'] = 'log_time,log_date,updated_at,comment,manager.name';
          this.getTaxAdvisorManagersList();
          this.assignManagerFormInit();
        } else if (this.USER.role === 'manager') {
          this.pConfig1['search_strings'] = 'log_time,log_date,updated_at,comment';
          console.log(this.singleMobileUser['work_manager']);
          if (this.singleMobileUser['work_manager'].status > 0) {
            if (this.work_statuses.length === 5) {
              this.work_statuses.shift();
            }
          }
          this.workStatusFormInit();
        }
        
        if (reloadDocs) {
          this.getAttributes();
        }
        this.changeStatusLang();
      }, error => {
        console.log(error);
      });
  }

  public getTaxAdvisorManagersList(): void {
    this._taxAdvisorsService.getTaxAdvisorsManagersList()
      .subscribe(res => {
        this.taxAdvisorManagers = [];
        res.manager.forEach(obj => {
          if (obj.is_active) {
            this.taxAdvisorManagers.push(obj);
          }
        });
        // console.log(this.taxAdvisorManagers);
      }, err => {
        console.log(err);
      });
  }

  public getManagerTimeLogByUser(): void {
    this._taxAdvisorsService.getTaxAdvisorManagerStatusForUser({
        user_id: this.singleMobileUserId
      })
      .subscribe(res => {
        console.log(res);
        if (this.USER.role === 'operator') {
          this.singleManagerTimeLogged = res.result;
          this.totalTimeLoggedForUser = res.log_total_work;
          // console.log(res);
        } else if (this.USER.role === 'manager') {
          this.singleManagerTimeLogged = res.result[0].log_work;
          this.totalTimeLoggedForUser = res.result[0].total_log_time;
          // console.log(res);
        } else {
          this.singleManagerTimeLogged = [];
        }
      }, err => {
        console.log(err);
      });
  }

  public editMobileUserInfo(): void {
    this.router.navigate([`mobile-users/${this.singleMobileUserId}/edit`]);
  }

  public changeWorkingStatus(): void {
     console.log(this.workStatusForm.value);
     this._taxAdvisorsService.changeTaxAdvisorManagerStatusForUser({
       progress_status: this.workStatusForm.value.workingStatus,
       user_id: this.singleMobileUserId
     }).subscribe(res => {
       console.log(res);
       this.singleMobileUserData(false);
     }, err => {
       console.log(err);
     });
   }

  public assignManager(): void {
   console.log(this.assignManagerForm.value);
   this._taxAdvisorsService.assignMobileUserToTaxAdvisorManager({
     manager_id: this.assignManagerForm.value.taxAdvisorManager,
     user_id: this.singleMobileUserId
   }).subscribe(res => {
     console.log(res);
     this.singleMobileUserData(false);
   }, err => {
     console.log(err);
   });
  }

  public editWorkTimeSubmit(): void {
   let convertedDate = moment_(this.editWorkTimeForm.value.date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");
   console.log({
     user_id: this.singleMobileUserId,
     log_time: this.editWorkTimeForm.value.time,
     log_date: convertedDate,
     log_work_id: this.editWorkTimeForm.value.id,
     comment: this.editWorkTimeForm.value.comment
   });
   this._taxAdvisorsService.logTimeByTaxAdvisorManagerForUser({
     user_id: this.singleMobileUserId,
     log_time: this.editWorkTimeForm.value.time,
     log_date: convertedDate,
     log_work_id: this.editWorkTimeForm.value.id,
     comment: this.editWorkTimeForm.value.comment
   }).subscribe(res => {
     this.modalRef.hide();
     this.getManagerTimeLogByUser();
   }, err => {
     console.log(err);
   });
  }
  public logWorkTimeSubmit(): void {
   let convertedDate = moment_(this.logWorkTimeForm.value.date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");
   console.log({
     user_id: this.singleMobileUserId,
     log_time: this.logWorkTimeForm.value.time,
     log_date: convertedDate,
     log_work_id: null,
     comment: this.logWorkTimeForm.value.comment
   });
   this._taxAdvisorsService.logTimeByTaxAdvisorManagerForUser({
     user_id: this.singleMobileUserId,
     log_time: this.logWorkTimeForm.value.time,
     log_date: convertedDate,
     log_work_id: null,
     comment: this.logWorkTimeForm.value.comment
   }).subscribe(res => {
     this.modalRef.hide();
     this.getManagerTimeLogByUser();
   }, err => {
     console.log(err);
   });
  }

  public acceptDocument(document): void {
    this._mobileUsersService.acceptSubmittedDocument({
      document_id: document.id,
      status: 1
    }).subscribe(res => {
      this.singleMobileUser['relevantSubcategories'].forEach(obj => {
        if (obj.id === res.result.sub_category_id) {
          obj['docs'].forEach(child_obj => {
            if (child_obj.id === res.result.id) {
              child_obj['status'] = parseInt(res.result.status, 10);
              child_obj['comment'] = res.result.comment;
            }
          });
        }
      });
      // this.getAttributes();
    }, err => {
      console.log(err);
    });
  }

  public rejectDocumentSubmit(): void {
    this._mobileUsersService.rejectSubmittedDocument({
      document_id: this.rejectDocumentForm.value.document_id,
      status: 2,
      comment: this.rejectDocumentForm.value.reject_comment
    }).subscribe(res => {
      this.singleMobileUser['relevantSubcategories'].forEach(obj => {
        if (obj.id === res.result.sub_category_id) {
          obj['docs'].forEach(child_obj => {
            if (child_obj.id === res.result.id) {
              child_obj['status'] = parseInt(res.result.status, 10);
              child_obj['comment'] = res.result.comment;
            }
          });
        }
      });
      this.modalRef.hide();
      // this.getAttributes();
    }, err => {
      console.log(err);
      this.modalRef.hide();
    });
  }
}
