import { Component, OnInit } from '@angular/core';
import { TaxAdvisorsService, AlertService } from "../../_services/index";
import { Router } from "@angular/router";

import { FormGroup, FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: 'app-create-taxadvisor',
  templateUrl: './create-taxadvisor.component.html',
  styleUrls: ['./create-taxadvisor.component.less']
})
export class CreateTaxAdvisorComponent implements OnInit {
  
  taxAdvisorForm: FormGroup;
  
  constructor(
    private _taxAdvisorsService: TaxAdvisorsService,
    private router: Router,
    private _alertService: AlertService,
    private fb: FormBuilder
  ) {
    this.createForm();
  }

  ngOnInit() { }
  
  createForm(): void {
    const emailPattern = "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?";
    const phonePattern = "^\\s*(?:\\+?\\d{1,4})?[- (]*\\d{4}(?:[- )]*\\d{4})?[- ]*\\d{5}(?: *[x/#]\\d+)?\\s*$";
    this.taxAdvisorForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(50)]],
      legal_name: ['', [Validators.required, Validators.maxLength(50)]],
      contact_person: ['', [Validators.required, Validators.maxLength(50)]],
      email: ['', [Validators.required, Validators.pattern(emailPattern), Validators.maxLength(40)]],
      company_details: ['', [Validators.required, Validators.maxLength(300)]],
      phone_number: ['', [Validators.required, Validators.pattern(phonePattern)]]
    });
  }
  
  public createTaxAdvisor(): void {
    console.log(this.taxAdvisorForm.value);
    const taxAdviserData = this.taxAdvisorForm.value;
    this._taxAdvisorsService.createTaxAdvisor(taxAdviserData)
      .subscribe(res => {
        console.log(res);
        this._alertService.success('Alert.You_successfully_created_new_taxadvisor');
        this.router.navigate(['/taxadvisors']);
      }, error => {
        console.log(error);
      });
  }

}
