import {Component, OnInit, Renderer2} from '@angular/core';
import { DecisionTreeService } from '../../_services/index';

@Component({
  selector: 'app-decision-tree-table',
  templateUrl: './decision-tree-table.component.html',
  styleUrls: ['./decision-tree-table.component.less']
})
export class DecisionTreeTableComponent implements OnInit {

  treeTableView: any;
  
  constructor(
    public _DecisionTreeService: DecisionTreeService,
    public renderer: Renderer2
  ) {
    this.getTree();
  }

  ngOnInit() { }
  
  public getTree(): void {
    this._DecisionTreeService.getDecisionTableTree()
      .subscribe(res => {
        console.log(res);
        this.treeTableView = res.result;
      }, err => {
        console.log(err);
      });
  }
  
  public goTo(destination: any): void {
    let e = document.getElementById(destination);
    let allElements = document.querySelectorAll('tr');
    setTimeout(() => {
      e.scrollIntoView();
      for (let i = 0; i < allElements.length; i++) {
        this.renderer.removeClass(allElements[i], 'active');
      }
      this.renderer.addClass(e, 'active');
    });
  }
  
}
