<div class="outlet-wrapper language-component-wrapper">

  <div class="outlet-header">
    <span class="lang_management_title">{{ 'Settings.Language_management.Title' | translate}}</span>
    <button class="main-action-btn no-icon-btn" (click)="addNewLanguageModal(newLanguage)">
      <span>{{ 'Settings.Language_management.Add_new_language_btn' | translate}}</span>
    </button>
  </div>
  <div class="outlet-main-content">
    <div class="import-section-wrapper">
      <p>{{ 'Settings.Language_management.Here_you_can_import_files_you_have_changed' | translate}}</p>
      <button class="secondary-action-btn" (click)="openImportModal(import)">
        <span>{{ 'Settings.Language_management.Upload_translations_btn' | translate}}</span>
        <i class="fas fa-cloud-upload-alt"></i>
      </button>
    </div>
    <div class="language-table-wrapper">
      <table class="language-table">
        <thead>
          <tr>
            <th class="name">{{ 'Settings.Language_management.Lang_name' | translate }}</th>
            <th class="total_strings">{{ 'Settings.Language_management.Untranslated_strings' | translate }}</th>
            <th class="export"></th>
            <th class="delete"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let i of registeredLanguages">
            <td>{{i.name}}</td>
            <td>{{i.untranslate}}</td>
            <td *ngIf="i.lang_code !== 'EN'">
              <button class="secondary-action-btn" (click)="openExportModal(export, i.lang_code)">
                <span>{{ 'Settings.Language_management.Export_btn' | translate }}</span>
                <i class="fas fa-cloud-download-alt"></i>
              </button>
            </td>
            <td *ngIf="i.lang_code !== 'EN'" class="delete_btn" (click)="deleteLanguage(i)">
              <i class="fas fa-trash"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>


<ng-template #export>
  <div class="modal-header">
    <h5 class="modal-title pull-left">{{ 'Settings.Language_management.Select_original_language_modal_title' | translate }}</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="language_export" name="translationsExportForm" id="translationsExportForm" [formGroup]="translationsExportForm"  #f="ngForm" (ngSubmit)="translationsExportForm.valid && exportTranslations()" novalidate>
      <select class="form-control" name="select" id="select" formControlName="select">
        <option *ngFor="let i of exportLangs" [value]="i.lang_code">{{i.name}}</option>
      </select>
    </form>
  </div>
  <div class="modal-footer">
    <div class="modal-footer">
      <button class="modal-submit-btn no-icon-btn" form="translationsExportForm">
        <span>{{ 'Shared.Submit_modal' | translate }}</span>
      </button>
      <button class="modal-cancel-btn no-icon-btn" type="button" (click)="modalRef.hide()">
        <span>{{ 'Shared.Close_modal' | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #import>
  <div class="modal-header">
    <h5 class="modal-title pull-left">{{ 'Settings.Language_management.Upload_changed_file_modal_title' | translate }}</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="language_import" name="translationsImportForm" id="translationsImportForm" [formGroup]="translationsImportForm"  #f="ngForm" (ngSubmit)="translationsImportForm.valid && importTranslations($event)" novalidate>
      <input formControlName="file" type="file">
    </form>
  </div>
  <div class="modal-footer">
    <button class="modal-submit-btn no-icon-btn" form="translationsImportForm">
      <span>{{ 'Shared.Submit_modal' | translate }}</span>
    </button>
    <button class="modal-cancel-btn no-icon-btn" type="button" (click)="modalRef.hide()">
      <span>{{ 'Shared.Close_modal' | translate }}</span>
    </button>
  </div>
</ng-template>

<ng-template #newLanguage>
  <div class="modal-header">
    <h5 class="modal-title pull-left">{{ 'Settings.Language_management.Select_new_language_modal_title' | translate }}</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="newLangForm" name="newLangForm" id="newLangForm" [formGroup]="newLangForm"  #f="ngForm" (ngSubmit)="newLangForm.valid && addNewLanguage()" novalidate>
      <select name="langSelect" class="form-control" id="langSelect" formControlName="langSelect">
        <option *ngFor="let i of isoLangNames" [value]="i.lang_code">{{i.name}}</option>
      </select>
    </form>
  </div>
  <div class="modal-footer">
    <button class="modal-submit-btn no-icon-btn" form="newLangForm">
      <span>{{ 'Shared.Submit_modal' | translate }}</span>
    </button>
    <button class="modal-cancel-btn no-icon-btn" type="button" (click)="modalRef.hide()">
      <span>{{ 'Shared.Close_modal' | translate }}</span>
    </button>
  </div>
</ng-template>
