import { Component, OnInit } from '@angular/core';
import { CategoryService, AlertService } from "../../_services/index";
import { Router, ActivatedRoute } from "@angular/router";

import { FormGroup, FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.less']
})
export class EditCategoryComponent implements OnInit {
  imagePlaceholder: any = '/assets/images/placeholder.png';
  imageToUpload: any = null;
  livePreviewImg: File = null;
  
  objectKeys = Object.keys;
  category_id: any = this.route.snapshot.params['id'];
  uploadPhotoForm: FormGroup;
  categoryData: any = {};
  translations: any[] = [];
  t: any[] = [];
  d: any[] = [];
  
  constructor(
    private _categoryService: CategoryService,
    private route: ActivatedRoute,
    private router: Router,
    private _alertService: AlertService,
    private fb: FormBuilder
  ) {
    this.getSingleCategoryById();
    this.photoForm();
  }

  ngOnInit() {}
  
  public photoForm(): void {
    this.uploadPhotoForm = this.fb.group({
      image: [null, [Validators.required]]
    });
  }
  
  public onDataChange(type, identifier, data): void {
    
    let pushFunc = (array) => {
      array.push({
        'language_code': identifier,
        'translation': data
      });
    };
    
    if (type === 'title') {
      this.t.forEach((item, index) => {
        if (item.language_code === identifier) {
          this.t.splice(index, 1);
        }
      });
      pushFunc(this.t);
    } else {
      this.d.forEach((item, index) => {
        if (item.language_code === identifier) {
          this.d.splice(index, 1);
        }
      });
      pushFunc(this.d);
    }
  }
  
  public getSingleCategoryById(): void {
    this._categoryService.getSingleCategory(this.category_id)
      .subscribe(res => {
        console.log(res);
        for (const w of Object.keys(res.categories['title_translation'])) {
          let i = {
            [w]: {
              'title': res.categories['title_translation'][w]
            }
          };
          for (const q of Object.keys(res.categories['description_translation'])) {
            if (q === w) {
              i[w]['descr'] = res.categories['description_translation'][q];
            }
          }
          this.translations.push(i);
        }
        this.categoryData = res.categories;
        console.log(this.translations);
      }, err => {
        console.log(err);
      });
  }
  
  public editCategory(data): void {
    let objToSend = {
      category_id: data.id,
      title_EN: data.title,
      title_translations: this.t,
      description_translations: this.d
    };
    
    this._categoryService.editSingleCategory(objToSend)
      .subscribe(res => {
        console.log(res);
        this._alertService.success('Alert.Changes saved successfully');
        this.router.navigate(['/']);
      }, err => {
        console.log(err);
      });
  
    if (this.imageToUpload) {
      this._categoryService.uploadPhoto(this.category_id, this.imageToUpload[0])
        .subscribe(res => {
          console.log(res);
        }, err => {
          console.log(err);
          this._alertService.error('Alert.Opps_something_went_wrong');
        });
    }
  }
  
  public handleImageInput(file: FileList) {
    this.imageToUpload = file;
    this.livePreviewImg = file.item(0);
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.categoryData.image_path = event.target.result;
    };
    reader.readAsDataURL(this.livePreviewImg);
  }
  
  public deletePhoto(): void {
    this.uploadPhotoForm.reset();
    this.categoryData.image_path = null;
    this.imageToUpload = false;
    this._categoryService.uploadPhoto(this.category_id)
      .subscribe(res => {
        this.uploadPhotoForm.reset();
        this.categoryData.image_path = null;
      }, err => {});
  }
  
  
}
