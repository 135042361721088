import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
// import { environment } from "../../environments/environment";
import { AlertService } from "./alert.service";
import { EnvService } from "./env.service";
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';


@Injectable()
export class SettingsService {
  
  // private API_BASE = environment.apiBase;
  USER: any;
  
  getUserData(): void {
    this.USER = JSON.parse(localStorage.getItem('currentUser'));
  }
  
  constructor(
    private env: EnvService,
    private http: HttpClient,
    private alertService: AlertService
  ) {
    this.getUserData();
  }
  
  /* LANGUAGE SECTION */
  
  public addNewLanguage(lang_code): Observable<any> {
    const url = `${this.env.apiBase}/language`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id)
      .append('code', lang_code);
    
    return this.http.post(url, {}, {params});
  }
  
  public deleteLanguage(lang_code): Observable<any> {
    const url = `${this.env.apiBase}/language`;
    let params = new HttpParams()
      .append('userId', this.USER.user_id)
      .append('code', lang_code);
    return this.http.delete(url, {
      params: params
    });
  }
  
  public getRegisteredLanguages(): Observable<any> {
    const url = `${this.env.apiBase}/language`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id);
    return this.http.get<Object>(url, {params});
  }
  
  public exportTranslations(data): Observable<any> {
    const url = `${this.env.apiBase}/export/translations`;
    this.getUserData();
    let headers = new HttpHeaders().append(
      'responseType', 'arraybuffer'
    );
    let params = new HttpParams()
      .append('userId', this.USER.user_id)
      .append('language_code_from', data.language_code_from)
      .append('language_code_to', data.language_code_to);
    
    return this.http.get(url, {
      params: params,
      responseType: 'arraybuffer'
    });
  }
  
  public importTranslations(file): Observable<any> {
    const url = `${this.env.apiBase}/import/translations`;
  
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);
    
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id);
    
    return this.http.post(url,  formData, {params});
  }
  
  public getSpecificIsoLangNames(array): any {
    return this.http.get<any>('/assets/ISO-631-1.json')
      .map((res) => {
        let arrayFixed = [];
        for (let i = 0; i < array.length; i++) {
          for (let key of Object.keys(res)) {
            if (array[i].lang_code === key.toUpperCase()) {
              for (let key2 of Object.keys(res[key])) {
                array[i]["name"] = res[key].name;
                array[i]["native_name"] = res[key].nativeName;
                arrayFixed.push(array[i]);
                break;
              }
            }
          }
        }
        return arrayFixed;
      });
  }
  
  public getIsoLangNames(array): any  {
    return this.http.get<Object>('/assets/ISO-631-1.json')
      .map((res) => {
        let arrayFixed = [];
        for (let i = 0; i < array.length; i++) {
          for (let key of Object.keys(res)) {
            res[key]['lang_code'] = key.toUpperCase();
            if (array[i].lang_code === key.toUpperCase()) {
              delete res[key];
            }
          }
        }
        for (const key of Object.keys(res)) {
          arrayFixed.push(res[key]);
        }
        return arrayFixed;
      });
  }
  
  /* USER ATTRIBUTES SECTION */
  
  public getAttributes(): Observable<any> {
    const url = `${this.env.apiBase}/attribute`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id);
    return this.http.get<Object>(url, {params});
  }
  
  public getSingleAttribute(data): Observable<any> {
    const url = `${this.env.apiBase}/attribute`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id)
      .append('attribute_id', data.id);
    return this.http.get<Object>(url, {params});
  }
  
  public addNewAttr(data): Observable<any> {
    const url = `${this.env.apiBase}/attribute`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id);
    return this.http.post(url, data, {params});
  }
  
  public deleteAttr(data): Observable<any> {
    const url = `${this.env.apiBase}/attribute`;
    let params = new HttpParams()
      .append('userId', this.USER.user_id)
      .append('attribute_id', data.id);
    return this.http.delete(url, {
      params: params
    });
  }
  
  
  /* FAQ SECTION */
  
  public getFaqQuestions(): any {
    const url = `${this.env.apiBase}/faq`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id);
    return this.http.get(url, {params});
  }
  
  public addNewFaq(data): Observable<any> {
    const url = `${this.env.apiBase}/faq`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id);
    return this.http.post(url, data, {params});
  }
  
  public editFaq(data): Observable<any> {
    const url = `${this.env.apiBase}/faq`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id);
    return this.http.post(url, data, {params});
  }
  
  public deleteFaq(data): Observable<any> {
    const url = `${this.env.apiBase}/faq`;
    let params = new HttpParams()
      .append('userId', this.USER.user_id)
      .append('ids', data.ids);
    return this.http.delete(url, {
      params: params
    });
  }
  
}
