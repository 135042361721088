<div class="edit-single-mobile-user-page-tpl container">
  <div class="row">
    <div class="top-bar col-12">
      <div class="breadcrumbs">
        <span class="breadcrumb-item">
          <a [routerLink]="['/mobile-users']">{{ 'Header.Mobile_users' | translate }}</a>
        </span>
        <span class="breadcrumb-item" *ngIf="singleMobileUser">
          <!--{{'Mobile_users.Edit_tax_advisor_manager' | translate}}-->
          <a [routerLink]="['/mobile-users/' + singleMobileUserId]">
            {{singleMobileUser.name + ' ' + singleMobileUser.second_name}}
          </a>
        </span>
      </div>
      <div class="controls">
        <button class="submit-form-btn no-icon-btn" form="editSingleMobileUserForm">
          <span>{{ 'Shared.Save' | translate }}</span>
        </button>
        <button class="cancel-form-btn no-icon-btn" [routerLink]="back_url" type="button">
          <span>{{ 'Shared.Cancel' | translate }}</span>
        </button>
      </div>
    </div>

    <div class="main-content col-12" *ngIf="singleMobileUserForm">
      <form name="editSingleMobileUserForm" id="editSingleMobileUserForm" class="editSingleMobileUserForm col-12 col-sm-12 col-md-9 col-lg-7 col-xl-7" (ngSubmit)="singleMobileUserForm.valid && editMobileUser()" [formGroup]="singleMobileUserForm" #f="ngForm" [ngClass]="{ 'ng-submitted-error': f.submitted && f.form.invalid }" novalidate>

        <div class="form-group">
          <div class="input-wrapper name">
            <label for="name">{{ 'Mobile_users.Name' | translate }}</label>
            <input class="form-control" name="name" id="name" formControlName="name"/>
          </div>
          <div class="form-control-feedback"
               *ngIf="f.submitted && !!singleMobileUserForm.controls.name.errors || !!singleMobileUserForm.controls.name.errors && (!!singleMobileUserForm.controls.name.dirty || !!singleMobileUserForm.controls.name.touched)">
            <p *ngIf="f.submitted && !!singleMobileUserForm.controls.name.errors.required || !!singleMobileUserForm.controls.name.errors.required">{{'Shared.Field_is_required' | translate}}</p>
            <p *ngIf="!!singleMobileUserForm.controls.name.errors.maxlength">{{'Shared.Field_is_too_long' | translate}}</p>
          </div>
        </div>

        <div class="form-group">
          <div class="input-wrapper second_name">
            <label for="second_name">{{ 'Mobile_users.Second_name' | translate }}</label>
            <input class="form-control" name="second_name" id="second_name" formControlName="second_name"/>
          </div>
          <div class="form-control-feedback"
               *ngIf="f.submitted && !!singleMobileUserForm.controls.second_name.errors || !!singleMobileUserForm.controls.second_name.errors && (!!singleMobileUserForm.controls.second_name.dirty || !!singleMobileUserForm.controls.second_name.touched)">
            <p *ngIf="f.submitted && !!singleMobileUserForm.controls.second_name.errors.required || !!singleMobileUserForm.controls.second_name.errors.required">{{'Shared.Field_is_required' | translate}}</p>
            <p *ngIf="!!singleMobileUserForm.controls.second_name.errors.maxlength">{{'Shared.Field_is_too_long' | translate}}</p>
          </div>
        </div>

        <div class="form-group">
          <div class="input-wrapper email">
            <label for="email">{{ 'Mobile_users.User_email' | translate }}</label>
            <input type="email" class="form-control" name="email" id="email" formControlName="email"/>
          </div>
          <div class="form-control-feedback"
               *ngIf="f.submitted && !!singleMobileUserForm.controls.email.errors || !!singleMobileUserForm.controls.email.errors && (!!singleMobileUserForm.controls.email.dirty || !!singleMobileUserForm.controls.email.touched)">
            <p *ngIf="f.submitted && !!singleMobileUserForm.controls.email.errors.required || !!singleMobileUserForm.controls.email.errors.required">{{'Shared.Field_is_required' | translate}}</p>
            <p *ngIf="f.submitted && !!singleMobileUserForm.controls.email.errors.pattern">{{'Shared.Email_isnt_valid' | translate}}</p>
            <p *ngIf="!!singleMobileUserForm.controls.email.errors.maxlength">{{'Shared.Field_is_too_long' | translate}}</p>
          </div>
        </div>

        <div class="form-group">
          <div class="input-wrapper address">
            <label for="address">{{ 'Mobile_users.User_address' | translate }}</label>
            <input class="form-control" name="address" id="address" formControlName="address"/>
          </div>
          <div class="form-control-feedback"
               *ngIf="f.submitted && !!singleMobileUserForm.controls.address.errors || !!singleMobileUserForm.controls.address.errors && (!!singleMobileUserForm.controls.address.dirty || !!singleMobileUserForm.controls.address.touched)">
            <p *ngIf="f.submitted && !!singleMobileUserForm.controls.address.errors.required || !!singleMobileUserForm.controls.address.errors.required">{{'Shared.Field_is_required' | translate}}</p>
            <p *ngIf="!!singleMobileUserForm.controls.address.errors.maxlength">{{'Shared.Field_is_too_long' | translate}}</p>
          </div>
        </div>

        <div class="form-group">
          <div class="input-wrapper attrs">
            <label>{{ 'Decision_tree.User_attributes' | translate}}</label>
            <ng-select [items]="attributesList"
                       bindLabel="description"
                       labelForId="id"
                       #select
                       [multiple]="true"
                       appendTo=".input-wrapper.attrs"
                       [notFoundText]="'Mobile_users.No_attributes_found' | translate"
                       [placeholder]="'Mobile_users.Select_attributes' | translate"
                       [closeOnSelect]="false"
                       [hideSelected]="false"
                       [searchable]="true"
                       formControlName="attributes">
              <ng-template ng-footer-tmp>
                <button class="main-action-btn no-icon-btn" (click)="closeSelect(select)">
                  {{'Mobile_users.Confirm_selected_attributes_btn' | translate}}
                </button>
              </ng-template>
            </ng-select>
            <div class="ng-select-controls" [class.hide_clear_all]="!singleMobileUserForm.value.attributes.length">
              <button class="cancel-form-btn no-icon-btn clear_all_documents_btn" type="button" (click)="clearAll()">
                {{'Mobile_users.Clear_all_attributes_btn' | translate}}
              </button>
              <button class="main-action-btn no-icon-btn add_more_documents_btn" type="button" (click)="openSelect(select)">
                {{'Mobile_users.Add_more_attributes_btn' | translate}}
              </button>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="input-wrapper additional_info">
            <label for="additional_info">{{ 'Mobile_users.Additional_information' | translate }}</label>
            <textarea class="form-control" name="additional_info" id="additional_info" formControlName="additional_info"></textarea>
          </div>
          <div class="form-control-feedback"
               *ngIf="f.submitted && !!singleMobileUserForm.controls.additional_info.errors || !!singleMobileUserForm.controls.additional_info.errors && (!!singleMobileUserForm.controls.additional_info.dirty || !!singleMobileUserForm.controls.additional_info.touched)">
            <p *ngIf="f.submitted && !!singleMobileUserForm.controls.additional_info.errors.required || !!singleMobileUserForm.controls.additional_info.errors.required">{{'Shared.Field_is_required' | translate}}</p>
            <p *ngIf="!!singleMobileUserForm.controls.additional_info.errors.maxlength">{{'Shared.Field_is_too_long' | translate}}</p>
          </div>
        </div>
      </form>

    </div>
  </div>
</div>
