import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
// import { environment } from "../../environments/environment";
import { EnvService } from "./env.service";

@Injectable()
export class DecisionTreeService {
  // private API_BASE = environment.apiBase;
  private USER: any;
  getUserData(): void {
    this.USER = JSON.parse(localStorage.getItem('currentUser'));
  }
  
  constructor(
    private env: EnvService,
    private http: HttpClient
  ) {
    this.getUserData();
  }
  
  public getDecisionTree(): Observable<any> {
    const url = `${this.env.apiBase}/decision/tree`;
    let params = new HttpParams()
      .append('userId', this.USER.user_id);
    return this.http.get<Object>(url, {params});
  }
  
  public getDecisionTableTree(): Observable<any> {
    const url = `${this.env.apiBase}/question`;
    let params = new HttpParams()
      .append('userId', this.USER.user_id);
    return this.http.get<Object>(url, {params});
  }
  
  public importDecisionTree(file): Observable<any> {
    const url = `${this.env.apiBase}/decision/tree`;
    let params = new HttpParams()
      .append('userId', this.USER.user_id);
    return this.http.post(url,  file, {params});
  }

}
