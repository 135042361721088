import {Component, OnInit} from '@angular/core';
import { CategoryService } from "../_services/category.service";
import { Observable } from "rxjs";
import { AlertService } from "../_services/alert.service";
import { Router } from "@angular/router";
import { SharedService } from "../_services/shared.service";

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.less']
})
export class CategoriesListComponent implements OnInit {
  imagePlaceholder: any = '/assets/images/placeholder.png';
  categories: any[];
  subcategories: any[];
  objectKeys = Object.keys;
  translations: any[] = [];
  
  lang: any = {
    active: localStorage.getItem('lang')
  };
  
  constructor(
    private _categoryService: CategoryService,
    private _alertService: AlertService,
    private router: Router,
    private _sharedService: SharedService
  ) {
    this.lang['active'] = this.lang['active'] === null ? 'ru' : this.lang['active'];
  }
  
  ngOnInit() {
    this._sharedService.changeLanguageEvent
      .subscribe((res) => {
        console.log(res);
        this.lang.active = res;
      });
    this.categoriesData();
  }
  
  getCategoriesObservable(): Observable<any> {
    return this._categoryService.getCategoriesAll();
  }
  
  public categoriesData(): void {
    this.getCategoriesObservable()
      .subscribe(res => {
        this.categories = res.categories;
      }, error => {
        console.log(error);
      });
  }
  
  /* RE-USED IN 'ACTIVE SUBCATEGORIES TAB' DIRECTIVE */
  public showSubcategories(category_id?: any): void {
    for (let i = 0; i < this.categories.length; i++) {
      if (this.categories[i].id === category_id) {
        this.subcategories = this.categories[i].subCategories;
      }
    }
  
    for (let t = 0; t < this.subcategories.length; t++) {
      this.translations = [];
  
      for (const w of Object.keys(this.subcategories[t].title_translation)) {
        let i = {
          [w]: {
            'title': this.subcategories[t].title_translation[w]
          }
        };
        for (const q of Object.keys(this.subcategories[t].description_translation)) {
          if (q === w) {
            i[w]['descr'] = this.subcategories[t].description_translation[q];
          }
        }
        this.translations.push(i);
      }
      this.subcategories[t]['translations'] = this.translations;
    }
    
  }
  
  public createSubcategoryLink(): void {
    let q = localStorage.getItem('openedCategories');
    this.router.navigate([`${q}/subcategory/new`]);
  }
  
  public deleteCategory(i): void {
    let inactive = 0;
    
    for (let y = 0; y < i.subCategories.length; y++) {
      if (!i.subCategories[y].is_active) {
        inactive = inactive + 1;
      }
    }
    
    if (inactive === i.subCategories.length || i.subCategories.length === 0) {
      let q = confirm(`Are you sure you want to delete category "${i.title}"`);
      if (q) {
        this._categoryService.deactivateCategory(i.id)
          .subscribe(res => {
            console.log(res);
            this._alertService.success(`Alert.Category deleted`);
            this.categoriesData();
          }, error => {
            console.log(error);
            this._alertService.error(error.statusText);
          });
      }
    } else {
      this._alertService.warn(`Alert.Please delete all Sub Category for this Category`);
    }
    
  }
  
  public deleteSubcategory(q): void {
    console.log(`Subcategory id to delete ${q.id}`);
    let w = confirm(`Are you sure you want to delete subcategory "${q.title}"`);
    if (w) {
      this._categoryService.deactivateSubcategory(q.id)
        .subscribe(res => {
          console.log(res);
          this._alertService.success('Alert.Subcategory deleted');
          this.categoriesData();
        }, error => {
          console.log(error);
          this._alertService.error(error.statusText);
        });
    }
  }
}
