<div class="container">
  <div class="row">
    <div class="categories sidebar col-4 col-sm-12 col-md-4 col-lg-4 col-xl-4">
      <div class="categories-header">
        <span>{{'DASHBOARD.Categories' | translate}}</span>
        <button class="main-action-btn no-text-btn" routerLink="/category/new">
          <i class="fas fa-plus"></i>
        </button>
      </div>
      <div class="categories-list-wrapper">
        <ul>
          <li class="subcategories-wrapper" [hidden]="!i.is_active" [ngClass]="{'data_null': !i.title}" *ngFor="let i of categories" value="{{i.id}}" data-title="{{i.category_title_to_show}}" categoryID="{{i.id}}" appActiveCategoryTabs>

            <img [src]="i.image_path || imagePlaceholder" [alt]="i.image_path || imagePlaceholder">

            <div class="subcategory-content-wrapper">
              <span *ngIf="lang.active === 'en'">{{i.title || 'Shared.No_title' | translate}}</span>
              <span *ngIf="lang.active === 'ru'">{{i.title_translation.RU || i.title}}</span>

              <span class="category-description" [ngClass]="{'data_null': !i.description && !i.description_translation.RU}">
              <span *ngIf="lang.active === 'en'">
                {{i.description || 'Shared.No_description' | translate}}
              </span>
              <span *ngIf="lang.active === 'ru'">
                {{i.description_translation.RU || i.description || 'Shared.No_description' | translate}}
              </span>
            </span>
            </div>

            <div class="dropdown context_menu_wrapper">
              <button class="context_menu_btn" type="button" id="category_context_menu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i  class="fas fa-ellipsis-h"></i>
              </button>
              <div class="dropdown-menu" aria-labelledby="category_context_menu">
                <a routerLink="category/{{i.id}}/edit">{{'Shared.Edit' | translate}}</a>
                <a (click)="deleteCategory(i)">{{'Shared.Delete' | translate}}</a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="subcategories main-content col-8 col-sm-12 col-md-8 col-lg-8 col-xl-8">
      <div class="subcategories-header">
        <span class="subcategories-header-title">{{'DASHBOARD.Subcategories' | translate}}</span>
        <button *ngIf="subcategories" class="main-action-btn" (click)="createSubcategoryLink()">
          <span>{{'DASHBOARD.Add_subcategory' | translate}}</span>
          <i class="fas fa-plus"></i>
        </button>
      </div>
      <div class="subcategories-list-wrapper">
        <ul>
          <li class="subcategories-translations-wrapper" [hidden]="!q.is_active" *ngFor="let q of subcategories" value="{{q.id}}" appActiveTabs>

            <div class="subcategory_title_wrapper">

              <span class="subcategory_title">
                <span *ngIf="lang.active === 'en'">{{q.title || 'Shared.No_title' | translate}}</span>
                <span *ngIf="lang.active === 'ru'">{{q.title_translation.RU || q.title}}</span>
              </span>

              <span class="controls">
                <i routerLink="subcategory/{{q.id}}/edit" class="fas fa-pencil-alt"></i>
                <i class="far fa-trash-alt" (click)="$event.stopPropagation(); deleteSubcategory(q)"></i>
              </span>
            </div>
            <div class="subcategory_descr_wrapper">
              <span *ngIf="lang.active === 'en'">
                {{q.description}}
              </span>
              <span *ngIf="lang.active === 'ru'">
                {{q.description_translation.RU || q.description}}
              </span>
            </div>

            <div (click)="$event.stopPropagation()" class="subcategories-translations">

              <div class="subcategories-data-wrapper">
                <div class="subcategory-title EN">
                  <span class="language_identifier">{{'Shared.Title' | translate}} (EN)</span>
                  <span class="subcategory_title_data"  [ngClass]="{'data_null': !q.title}">
                    {{q.title || 'Shared.No_subcategory_title' | translate}}
                  </span>
                </div>
                <div class="subcategory-descr EN">
                  <span class="language_identifier">{{ 'Shared.Description' | translate }} (EN)</span>
                  <span class="subcategory_descr_data" [ngClass]="{'data_null': !q.description}">
                    {{q.description || 'Shared.No_subcategory_description' | translate}}
                  </span>
                </div>
              </div>

              <div class="subcategories-data-wrapper" *ngFor="let t of q.translations">
                <div *ngFor="let key of objectKeys(t)">
                  <div class="subcategory-title {{key}}">
                    <span class="language_identifier">{{'Shared.Title' | translate}} ({{key}})</span>
                    <span class="subcategory_title_data" [ngClass]="{'data_null': !t[key].title}">
                      {{t[key].title || 'Shared.No_subcategory_title' | translate}}
                    </span>
                  </div>
                  <div class="subcategory-descr {{key}}">
                    <span class="language_identifier">{{ 'Shared.Description' | translate }} ({{key}})</span>
                    <span class="subcategory_descr_data" [ngClass]="{'data_null': !t[key].descr}">
                      {{t[key].descr || 'Shared.No_subcategory_description' | translate}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>



<!--THE OLD VERSION OF THE CATEGORIES PAGE WITH EDITABLE FIELDS IN SUBCATEGORIES-->
<!--
<div class="container">
  <div class="row">
    <div class="categories sidebar col-4 col-sm-12 col-md-4 col-lg-4 col-xl-4">
      <div class="categories-header">
        <span>{{'DASHBOARD.Categories' | translate}}</span>
        <button class="main-action-btn add-category-btn" routerLink="/category/new">
          <i class="fas fa-plus"></i>
        </button>
      </div>
      <div class="categories-list-wrapper">
        <ul>
          <li class="subcategories-wrapper" [hidden]="!i.is_active" [ngClass]="{'data_null': !i.title}" *ngFor="let i of categories" value="{{i.id}}" data-title="{{i.category_title_to_show}}" categoryID="{{i.id}}" appActiveCategoryTabs>
            &lt;!&ndash;<span>{{i.category_title_to_show}}</span>&ndash;&gt;
            <span *ngIf="lang.active === 'en'">{{i.title || 'Shared.No_title' | translate}}</span>
            <span *ngIf="lang.active === 'ru'">{{i.title_translation.RU || i.title}}</span>
            <span class="category-description" [ngClass]="{'data_null': !i.description && !i.description_translation.RU}">
              &lt;!&ndash;<span>
                {{i.category_desciption_to_show || 'Shared.No_description' | translate}}
              </span>&ndash;&gt;
              <span *ngIf="lang.active === 'en'">
                {{i.description || 'Shared.No_description' | translate}}
              </span>
              <span *ngIf="lang.active === 'ru'">
                {{i.description_translation.RU || i.description || 'Shared.No_description' | translate}}
              </span>
            </span>

            <div class="dropdown context_menu_wrapper">
              <button class="context_menu_btn" type="button" id="category_context_menu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i  class="fas fa-ellipsis-h"></i>
              </button>
              <div class="dropdown-menu" aria-labelledby="category_context_menu">
                <a routerLink="category/{{i.id}}/edit">{{'Shared.Edit' | translate}}</a>
                <a (click)="deleteCategory(i)">{{'Shared.Delete' | translate}}</a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="subcategories main-content col-8 col-sm-12 col-md-8 col-lg-8 col-xl-8">
      <div class="subcategories-header">
        <span>{{'DASHBOARD.Subcategories' | translate}}</span>
        <button *ngIf="subcategories" class="main-action-btn add-subcategory-btn" (click)="createSubcategoryLink()">
          <i class="fas fa-plus"></i>
          {{'DASHBOARD.Add_subcategory' | translate}}
        </button>
      </div>
      <div class="subcategories-list-wrapper">
        <ul>
          <li class="subcategories-translations-wrapper" [hidden]="!q.is_active" *ngFor="let q of subcategories; let i=index;" value="{{q.id}}" appActiveTabs>

            <div class="subcategory_title_wrapper">
              <span class="subcategory_title">
                <span *ngIf="lang.active === 'en'">{{q.title || 'Shared.No_title' | translate}}</span>
                <span *ngIf="lang.active === 'ru'">{{q.title_translation.RU || q.title}}</span>
                &lt;!&ndash;{{q.title || 'No title'}}&ndash;&gt;
              </span>
              <span class="controls">
                <i class="fas fa-pencil-alt"></i>
                <i class="far fa-trash-alt" (click)="$event.stopPropagation(); deleteSubcategory(q)"></i>

                <button *ngIf="q.showControls" (click)="editSubcategoryData('decline', q)" class="declineChangesInSubcategories">
                  <i class="fas fa-times"></i>
                  {{'Shared.Cancel' | translate}}
                </button>
                <button *ngIf="q.showControls" [disabled]="!q.title" (click)="$event.stopPropagation();  editSubcategoryData('accept', q)" class="saveChangesInSubcategories">
                  <i (click)="$event.stopPropagation(); editSubcategoryData('accept', q)" class="far fa-check-square"></i>
                  {{'Shared.Save' | translate}}
                </button>

              </span>
            </div>
            <div class="subcategory_descr_wrapper" *ngIf="q.description">
              &lt;!&ndash;<span class="subcategory_descr">{{q.description}}</span>&ndash;&gt;
              <span *ngIf="lang.active === 'en'">
                {{q.description || 'Shared.No_description' | translate}}
              </span>
              <span *ngIf="lang.active === 'ru'">
                {{q.description_translation.RU || q.description || 'Shared.No_description' | translate}}
              </span>
            </div>

            <div (click)="$event.stopPropagation()" class="subcategories-translations">
              <div class="subcategories-data-wrapper EN">
                <div class="subcategory-title">
                  <span class="language_identifier">{{'Shared.Title' | translate}} (EN) <span class="required_asterisk">*</span></span>
                  <span class="subcategory_title_data"  [ngClass]="{'data_null': !q.title}" [hidden]="q.edit_title" (click)="toggleToInput($event, q)">
                    {{q.title || 'Shared.No_subcategory_title' | translate}}
                    <i class="fas fa-pencil-alt"></i>
                  </span>
                  <div [hidden]="!q.edit_title" class="edit_info_input">
                    <input name="title_en" class="single_required_input" [(ngModel)]="q.title" required>
                  </div>
                </div>
                <div class="subcategory-descr">
                  <span class="language_identifier">{{ 'Shared.Description' | translate }} (EN)</span>
                  <span class="subcategory_descr_data" [ngClass]="{'data_null': !q.description}" [hidden]="q.edit_descr" (click)="toggleToInput($event, q)">
                    {{q.description || 'Shared.No_subcategory_description' | translate}}
                    <i class="fas fa-pencil-alt"></i>
                  </span>
                  <div [hidden]="!q.edit_descr" class="edit_info_input">
                    <input [(ngModel)]="q.description">
                  </div>
                </div>
              </div>

              <div class="subcategories-data-wrapper RU">
                <div class="subcategory-title">
                  <span class="language_identifier">{{'Shared.Title' | translate}} (RU)</span>
                  <span class="subcategory_title_data" [ngClass]="{'data_null': !q.title_translation.RU}" [hidden]="q.edit_title" (click)="toggleToInput($event, q)">
                    {{q.title_translation.RU || 'Shared.No_subcategory_title' | translate}}
                    <i class="fas fa-pencil-alt"></i>
                  </span>

                  <div [hidden]="!q.edit_title" class="edit_info_input">
                    <input [(ngModel)]="q.title_translation.RU">
                  </div>
                </div>
                <div class="subcategory-descr">
                  <span class="language_identifier">{{ 'Shared.Description' | translate }} (RU)</span>
                  <span class="subcategory_descr_data" [ngClass]="{'data_null': !q.description_translation.RU}" [hidden]="q.edit_descr" (click)="toggleToInput($event, q)">
                    {{q.description_translation.RU || 'Shared.No_subcategory_description' | translate}}
                    <i class="fas fa-pencil-alt"></i>
                  </span>
                  <div [hidden]="!q.edit_descr" class="edit_info_input">
                    <input [(ngModel)]="q.description_translation.RU">
                  </div>

                </div>
              </div>
              <div class="subcategories-data-wrapper DE">
                <div class="subcategory-title">
                  <span class="language_identifier">{{'Shared.Title' | translate}} (DE)</span>
                  <span class="subcategory_title_data"  [ngClass]="{'data_null': !q.title_translation.DE}" [hidden]="q.edit_title" (click)="toggleToInput($event, q)">
                    {{q.title_translation.DE ||  'Shared.No_subcategory_title' | translate}}
                    <i class="fas fa-pencil-alt"></i>
                  </span>
                  <div [hidden]="!q.edit_title" class="edit_info_input">
                    <input [(ngModel)]="q.title_translation.DE">
                  </div>
                </div>
                <div class="subcategory-descr">
                  <span class="language_identifier">{{ 'Shared.Description' | translate }} (DE)</span>
                  <span class="subcategory_descr_data" [ngClass]="{'data_null': !q.description_translation.DE}" [hidden]="q.edit_descr" (click)="toggleToInput($event, q)">
                    {{q.description_translation.DE || 'Shared.No_subcategory_description' | translate}}
                    <i class="fas fa-pencil-alt"></i>
                  </span>
                  <div [hidden]="!q.edit_descr" class="edit_info_input">
                    <input [(ngModel)]="q.description_translation.DE">
                  </div>
                </div>
              </div>
              <div class="subcategories-data-wrapper PL">
                <div class="subcategory-title">
                  <span class="language_identifier">{{'Shared.Title' | translate}} (PL)</span>
                  <span class="subcategory_title_data"  [ngClass]="{'data_null': !q.title_translation.PL}" [hidden]="q.edit_title" (click)="toggleToInput($event, q)">
                    {{q.title_translation.PL ||  'Shared.No_subcategory_title' | translate}}
                    <i class="fas fa-pencil-alt"></i>
                  </span>
                  <div [hidden]="!q.edit_title" class="edit_info_input">
                    <input [(ngModel)]="q.title_translation.PL">
                  </div>
                </div>
                <div class="subcategory-descr">
                  <span class="language_identifier">{{ 'Shared.Description' | translate }} (PL)</span>
                  <span class="subcategory_descr_data" [ngClass]="{'data_null': !q.description_translation.PL}" [hidden]="q.edit_descr" (click)="toggleToInput($event, q)">
                    {{q.description_translation.PL || 'Shared.No_subcategory_description' | translate}}
                    <i class="fas fa-pencil-alt"></i>
                  </span>
                  <div [hidden]="!q.edit_descr" class="edit_info_input">
                    <input [(ngModel)]="q.description_translation.PL">
                  </div>
                </div>
              </div>
              <div class="subcategories-data-wrapper UK">
                <div class="subcategory-title">
                  <span class="language_identifier">{{'Shared.Title' | translate}} (UK)</span>
                  <span class="subcategory_title_data"  [ngClass]="{'data_null': !q.title_translation.UK}" [hidden]="q.edit_title" (click)="toggleToInput($event, q)">
                    {{q.title_translation.UK ||  'Shared.No_subcategory_title' | translate}}
                    <i class="fas fa-pencil-alt"></i>
                  </span>
                  <div [hidden]="!q.edit_title" class="edit_info_input">
                    <input [(ngModel)]="q.title_translation.UK">
                  </div>
                </div>
                <div class="subcategory-descr">
                  <span class="language_identifier">{{ 'Shared.Description' | translate }} (UK)</span>
                  <span class="subcategory_descr_data" [ngClass]="{'data_null': !q.description_translation.UK}" [hidden]="q.edit_descr" (click)="toggleToInput($event, q)">
                    {{q.description_translation.UK || 'Shared.No_subcategory_description' | translate}}
                    <i class="fas fa-pencil-alt"></i>
                  </span>
                  <div [hidden]="!q.edit_descr" class="edit_info_input">
                    <input [(ngModel)]="q.description_translation.UK">
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
-->
