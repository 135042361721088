import {Directive, Input, TemplateRef, ViewContainerRef, OnInit} from '@angular/core';

/*Permissions:
  admin, manager, any, no one*/

@Directive({
  selector: '[permission]',
})
export class PermissionDirective implements OnInit {
  user = JSON.parse(localStorage.getItem('currentUser'));
  
  // usage is like this <button *permission="['admin', 'manager']">Click here</button>
  @Input() set permission(permission_given: string[]) {
    for (let i = 0; i < permission_given.length; i++) {
      if (permission_given[i] === this.user.role) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        break;
      } else {
        this.viewContainer.clear();
      }
    }
  }
  
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef) {}
  
  ngOnInit() {}
  
}
