<app-node (importTreeEvent)="receiveMessage1($event)" (changesWereMade)="receiveMessage2()" (nodeDeleteEvent)="receiveMessage($event)" *ngIf="QAList" [nodes]="QAList" [hasParent]="hasParent" [attributes]="attributes"></app-node>

<div class="oc-reports">
  <ng-container *ngFor="let nodes of QAList?.children; first as isFirst; last as isLast">
    <div class="oc-org-container">
      <div class="oc-h-bar-container">
        <div class="oc-h-bar oc-border" [style.border-color]="isFirst?'transparent':''"></div>
        <div class="oc-border"></div>
        <div class="oc-h-bar oc-border" [style.border-color]="isLast?'transparent':''"></div>
      </div>
      <app-node-wrap (importTreeEvent)="receiveMessage1($event)" (changesWereMade)="receiveMessage2()" (deleteNodeEvent)="receiveMessage($event)" [QAList]="nodes" [hasParent]="true" [attributes]="attributes"></app-node-wrap></div>
  </ng-container>
</div>
