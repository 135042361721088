import {Injectable} from "@angular/core";
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from "@angular/router";

@Injectable()
export class AuthGuard implements CanActivate {
  
  constructor(
    private router: Router
  ) {}
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const requiredRole = route.data['role'];
    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (localStorage.getItem('currentUser') && (requiredRole === user.role || requiredRole === 'anyone')) {
      return true;
    } else if (localStorage.getItem('currentUser') && (user.role === 'manager' && requiredRole === 'operator')) {
      this.router.navigate(['/mobile-users']);
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
    
    /*const expected_roles = !route.data.role ? ['all'] : route.data.role;
    if (user) {
      for (let i = 0; i <= expected_roles.length; i++) {
        if (user.role === expected_roles[i] || expected_roles[i] === 'all') {
          // logged and role is as expected so return true
          return true;
        } else {
          this.router.navigate(['/']);
          return true;
        }
      }
    }*/
    
    // not logged in so redirect to login page with the return url
    // this.router.navigate(['/login'], {queryParams: {returnUrl: state.url }});
    /*this.router.navigate(['/login']);
    return false;*/
    
  }
}
