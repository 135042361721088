<div class="container-fluid">
  <div class="row">
    <div class="top-bar col-12">
      <div class="controls">
        <button [disabled]="!treeWasChanged" type="button" class="submit-form-btn no-icon-btn save-tree-changes" (click)="importDecisionTree()">
          <span>{{ 'Shared.Save_changes' | translate }}</span>
        </button>
        <button [disabled]="!treeWasChanged" class="cancel-form-btn no-icon-btn" (click)="getTree('cancel')" type="button">
          <span>{{ 'Shared.Cancel_changes' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</div>

<drag-scroll class="tree-wrapper">
  <div class="visualized-decision-tree">
    <app-node-wrap (importTreeEvent)="importDecisionTree()" (changesWereMade)="receiveMessageAboutChanges()" (deleteNodeEvent)="receiveMessage($event)" [QAList]="QAList" [attributes]="availableAttributes"></app-node-wrap>
  </div>
</drag-scroll>
