import { Component, OnInit } from '@angular/core';
import { MobileUsersService } from "../_services/mobile-users.service";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { SharedService } from "../_services/shared.service";

@Component({
  selector: 'app-mobile-users',
  templateUrl: './mobile-users.component.html',
  styleUrls: ['./mobile-users.component.less']
})
export class MobileUsersComponent implements OnInit {
  mobileUsers: any = [];
  mobileUsersTemp: any = [];
  pConfig1 = {
    itemsPerPage: 5,
    currentPage: 1,
    sortField: '',
    reverseOrder: false,
    search: ''
  };
  work_statuses = [{
    id: 0,
    name: '',
    translated_name: 'Shared.Mobile_user_work_status_0'
  }, {
    id: 1,
    name: '',
    translated_name: 'Shared.Mobile_user_work_status_1'
  }, {
    id: 2,
    name: '',
    translated_name: 'Shared.Mobile_user_work_status_2'
  }, {
    id: 3,
    name: '',
    translated_name: 'Shared.Mobile_user_work_status_3'
  }, {
    id: 4,
    name: '',
    translated_name: 'Shared.Mobile_user_work_status_4'
  }];
  lang: any = {
    active: localStorage.getItem('lang')
  };
  
  constructor(
    private _mobileUsersService: MobileUsersService,
    private router: Router,
    private _sharedService: SharedService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.getMobileUsers();
    this._sharedService.changeLanguageEvent
      .subscribe((res) => {
        this.lang.active = res;
        this.changeStatusLang();
      });
  }
  
  changeStatusLang(): void {
    this.translate.get(['Shared.Mobile_user_work_status_0', 'Shared.Mobile_user_work_status_1', 'Shared.Mobile_user_work_status_2', 'Shared.Mobile_user_work_status_3', 'Shared.Mobile_user_work_status_4'])
      .subscribe(response => {
        console.log(response);
        Object.entries(response).forEach(
          ([key, value]) => {
            this.work_statuses.forEach(obj => {
              if (obj.translated_name === key) {
                obj.name = value.toString();
              }
              if (this.mobileUsers) {
                this.mobileUsers.forEach(child_obj => {
                  if (child_obj.work_manager.status === obj.id) {
                    child_obj.work_manager.status_name = obj.name;
                  }
                });
              }
            });
          }
        );
        this.work_statuses = [...this.work_statuses];
      });
  }
  sortBy1 = function(sortField) {
    this.pConfig1['reverseOrder'] = (this.pConfig1['sortField'] === sortField) ? !this.pConfig1['reverseOrder'] : false;
    this.pConfig1['sortField'] = sortField;
  };
  pageChange1(event: any): void {
    this.pConfig1['currentPage'] = event;
  }
  
  public userDetailsRedirect(i): void {
    this.router.navigate([`mobile-users/${i.id}`]);
  }
  
  private getMobileUsers(): any {
    this._mobileUsersService.getMobileUsers()
      .subscribe(res => {
        res.users.forEach(obj => {
          if (Object.entries(obj.work_manager).length < 1) {
            obj.work_manager = {
              id: '', name: "", status: '', status_name: ""
            };
          }
        });
        this.mobileUsers = res.users;
        this.changeStatusLang();
      }, err => {
        console.log(err);
      });
  }

}
