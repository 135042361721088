<div class="create-tax-advisors-manager-page-tpl container">
  <div class="row">
    <div class="top-bar col-12">
      <div class="breadcrumbs">
            <span class="breadcrumb-item">
              <a [routerLink]="['/taxadvisors']">{{ 'Shared.Tax_advisors' | translate }}</a>
            </span>
        <span class="breadcrumb-item">{{ 'Create_edit_tax_advisors_manager.New_tax_advisor_manager' | translate }}</span>
      </div>
      <div class="controls">
        <button class="submit-form-btn no-icon-btn" form="createTaxAdvisorManagerForm">
          <span>{{ 'Shared.Save' | translate }}</span>
        </button>
        <button class="cancel-form-btn no-icon-btn" routerLink="/taxadvisors/{{taxAdvisorId}}" type="button">
          <span>{{ 'Shared.Cancel' | translate }}</span>
        </button>
      </div>
    </div>

    <div class="main-content col-12">
      <form name="createTaxAdvisorManagerForm" id="createTaxAdvisorManagerForm" class="createTaxAdvisorManagerForm col-12 col-sm-12 col-md-9 col-lg-7 col-xl-7" (ngSubmit)="taxAdvisorManagerForm.valid && createTaxAdvisorsManager()" [formGroup]="taxAdvisorManagerForm" #f="ngForm" [ngClass]="{ 'ng-submitted-error': f.submitted && f.form.invalid }" novalidate>

        <div class="form-group">
          <div class="input-wrapper name">
            <label for="name">{{ 'Create_edit_tax_advisors_manager.Name' | translate }}</label>
            <input class="form-control" name="name" id="name" formControlName="name"/>
          </div>
          <div class="form-control-feedback"
               *ngIf="f.submitted && !!taxAdvisorManagerForm.controls.name.errors || !!taxAdvisorManagerForm.controls.name.errors && (!!taxAdvisorManagerForm.controls.name.dirty || !!taxAdvisorManagerForm.controls.name.touched)">
            <p *ngIf="f.submitted && !!taxAdvisorManagerForm.controls.name.errors.required || !!taxAdvisorManagerForm.controls.name.errors.required">{{'Shared.Field_is_required' | translate}}</p>
            <p *ngIf="!!taxAdvisorManagerForm.controls.name.errors.maxlength">{{'Shared.Field_is_too_long' | translate}}</p>
          </div>
        </div>

        <div class="form-group">
          <div class="input-wrapper email">
            <label for="email">{{ 'Create_edit_tax_advisors_manager.Email' | translate }}</label>
            <input type="email" class="form-control" name="email" id="email" formControlName="email"/>
          </div>
          <div class="form-control-feedback"
               *ngIf="f.submitted && !!taxAdvisorManagerForm.controls.email.errors || !!taxAdvisorManagerForm.controls.email.errors && (!!taxAdvisorManagerForm.controls.email.dirty || !!taxAdvisorManagerForm.controls.email.touched)">
            <p *ngIf="f.submitted && !!taxAdvisorManagerForm.controls.email.errors.required || !!taxAdvisorManagerForm.controls.email.errors.required">{{'Shared.Field_is_required' | translate}}</p>
            <p *ngIf="f.submitted && !!taxAdvisorManagerForm.controls.email.errors.pattern">{{'Shared.Email_isnt_valid' | translate}}</p>
            <p *ngIf="!!taxAdvisorManagerForm.controls.email.errors.maxlength">{{'Shared.Field_is_too_long' | translate}}</p>
          </div>
        </div>

        <div class="form-group">
          <div class="input-wrapper phone_number">
            <label for="phone_number">{{ 'Create_edit_tax_advisors_manager.Phone_number' | translate }}</label>
            <input type="tel" class="form-control" name="phone_number" id="phone_number" formControlName="phone_number"/>
          </div>
          <div class="form-control-feedback"
               *ngIf="f.submitted && !!taxAdvisorManagerForm.controls.phone_number.errors || !!taxAdvisorManagerForm.controls.phone_number.errors && (!!taxAdvisorManagerForm.controls.phone_number.dirty || !!taxAdvisorManagerForm.controls.phone_number.touched)">
            <p *ngIf="f.submitted && !!taxAdvisorManagerForm.controls.phone_number.errors.required || !!taxAdvisorManagerForm.controls.phone_number.errors.required">{{'Shared.Field_is_required' | translate}}</p>
            <p *ngIf="!!taxAdvisorManagerForm.controls.phone_number.errors.pattern">{{'Shared.Phone_number_is_not_valid' | translate}}</p>
          </div>
        </div>

        <div class="form-group">
          <div class="input-wrapper password">
            <label for="password">{{ 'Create_edit_tax_advisors_manager.Password' | translate }}</label>
            <input type="password" class="form-control" name="password" id="password" formControlName="password"/>
          </div>
          <div class="form-control-feedback"
               *ngIf="f.submitted && !!taxAdvisorManagerForm.controls.password.errors || !!taxAdvisorManagerForm.controls.password.errors && (!!taxAdvisorManagerForm.controls.password.dirty || !!taxAdvisorManagerForm.controls.password.touched)">
            <p *ngIf="f.submitted && !!taxAdvisorManagerForm.controls.password.errors.required || !!taxAdvisorManagerForm.controls.password.errors.required">{{'Shared.Field_is_required' | translate}}</p>
            <p *ngIf="!!taxAdvisorManagerForm.controls.password.errors.minlength" translate [translateParams]="{ requiredLength: taxAdvisorManagerForm.controls.password.errors.minlength.requiredLength, charactersLeft: taxAdvisorManagerForm.controls.password.errors.minlength.requiredLength - taxAdvisorManagerForm.controls.password.errors.minlength.actualLength }">
              Shared.Password_length_required
            </p>
          </div>
        </div>

        <div class="form-group">
          <div class="input-wrapper password_confirmation">
            <label for="password_confirmation">{{ 'Create_edit_tax_advisors_manager.Password_confirmation' | translate }}</label>
            <input type="password" appConfirmEqualValidator="password" class="form-control" name="password_confirmation" id="password_confirmation" formControlName="password_confirmation"/>
          </div>
          <div class="form-control-feedback"
               *ngIf="f.submitted && !!taxAdvisorManagerForm.controls.password_confirmation.errors || !!taxAdvisorManagerForm.controls.password_confirmation.errors && (!!taxAdvisorManagerForm.controls.password_confirmation.dirty || !!taxAdvisorManagerForm.controls.password_confirmation.touched)">
            <p *ngIf="f.submitted && !!taxAdvisorManagerForm.controls.password_confirmation.errors.required || !!taxAdvisorManagerForm.controls.password_confirmation.errors.required">{{'Shared.Field_is_required' | translate}}</p>
            <p *ngIf="!!taxAdvisorManagerForm.controls.password_confirmation.errors.notEqual">{{'Shared.Passwords_arent_equal' | translate}}</p>
          </div>
        </div>
      </form>

      <!--<p>Form value: {{taxAdvisorManagerForm.value | json}}</p>
      <p>Form value: {{taxAdvisorManagerForm.status | json}}</p>-->

    </div>

      <!--<form [formGroup]="taxAdvisorManagerForm" (ngSubmit)="createTaxAdvisorsManager()" name="createTaxAdvisorManagerForm" id="createTaxAdvisorManagerForm1">
        <div class="form-group">
          <label class="center-block" for="">
            Name:
            <input class="form-control" formControlName="name">
          </label>
          <div class="form-control-feedback"
               *ngIf="!!taxAdvisorManagerForm.controls.name.errors && (!!taxAdvisorManagerForm.controls.name.dirty || !!taxAdvisorManagerForm.controls.name.touched)">
            <p *ngIf="!!taxAdvisorManagerForm.controls.name.errors.required">Password is required</p>
            <p *ngIf="!!taxAdvisorManagerForm.controls.name.errors.minlength">Password must be {{taxAdvisorManagerForm.controls.name.errors.minlength.requiredLength}} characters long
          </div>

          <div class="form-control-feedback1"
               *ngIf="taxAdvisorManagerForm.controls['name'].errors && (taxAdvisorManagerForm.controls['name'].errors.dirty || taxAdvisorManagerForm.controls['name'].errors.touched)">
            <p *ngIf="taxAdvisorManagerForm.controls['name'].errors.required">Password is required</p>
            <p *ngIf="taxAdvisorManagerForm.controls['name'].errors.minlength">Password must be 8 characters long, we need another {{taxAdvisorManagerForm.controls['name'].errors.minlength.requiredLength - taxAdvisorManagerForm.controls['name'].errors.minlength.actualLength}} characters </p>
          </div>
        </div>
      </form>
      <p>Form value: {{taxAdvisorManagerForm.value | json}}</p>
      <p>Form value: {{taxAdvisorManagerForm.status | json}}</p>
      <p>Name value: {{taxAdvisorManagerForm.get('name').value }}</p>-->

    <!--<div class="main-content col-12" *ngIf="newTaxAdvisorsManager">
      <form name="createTaxAdvisorManagerForm" id="createTaxAdvisorManagerForm" class="createTaxAdvisorManagerForm col-12 col-sm-12 col-md-9 col-lg-7 col-xl-7" (ngSubmit)="f.form.valid && createTaxAdvisorsManager()" #f="ngForm" novalidate>

        <div class="form-group" [ngClass]="{ 'has-error': f.submitted && f.form.invalid }">
          <div class="input-wrapper name">
            <label for="name">{{ 'Create_edit_tax_advisors_manager.Name' | translate }}</label>
            <input class="form-control" name="name" id="name" [(ngModel)]="newTaxAdvisorsManager.name" #name="ngModel" required/>
            <div *ngIf="f.submitted && !name.valid" class="form-error-block help-block">
              {{'Shared.Field_is_required' | translate}}
            </div>
          </div>

          <div class="input-wrapper email">
            <label for="email">{{ 'Create_edit_tax_advisors_manager.Email' | translate }}</label>
            <input type="email" class="form-control" name="email" id="email" [(ngModel)]="newTaxAdvisorsManager.email" #email="ngModel" required/>
            <div *ngIf="f.submitted && !email.valid" class="form-error-block help-block">
              {{'Shared.Field_is_required' | translate}}
            </div>
          </div>

          <div class="input-wrapper password">
            <label for="password">{{ 'Create_edit_tax_advisors_manager.Password' | translate }}</label>
            <input type="password" class="form-control" name="password" id="password" [(ngModel)]="newTaxAdvisorsManager.password" (ngModelChange)="newTaxAdvisorsManager.password = $event + ''" #password="ngModel" minlength="6" maxlength="20" required/>
            &lt;!&ndash;{{password.errors | json}}&ndash;&gt;
            <div *ngIf="f.submitted && !password.valid" class="form-error-block help-block">
              {{'Shared.Field_is_required' | translate}}
            </div>
            &lt;!&ndash;<div *ngIf="f.submitted && !password.errors.minlength" class="form-error-block help-block">
              {{'Shared.Min_length_password_error' | translate}}
            </div>&ndash;&gt;
            &lt;!&ndash;<div *ngIf="f.submitted && !password.errors.maxlength" class="form-error-block help-block">
              {{'Shared.Max_length_password_error' | translate}}
            </div>&ndash;&gt;
          </div>

          <div class="input-wrapper password_confirmation">
            <label for="password_confirmation">{{ 'Create_edit_tax_advisors_manager.Password_confirmation' | translate }}</label>
            <input type="password" appConfirmEqualValidator="password" class="form-control" name="password_confirmation" id="password_confirmation" [(ngModel)]="newTaxAdvisorsManager.password_confirmation" #password_confirmation="ngModel" minlength="6" maxlength="20" required/>
            &lt;!&ndash;<div *ngIf="f.submitted && !password_confirmation.valid" class="form-error-block help-block">
              {{'Shared.Field_is_required' | translate}}
            </div>&ndash;&gt;
            <div *ngIf="!!password_confirmation.errors?.notEqual" class="form-error-block help-block">
              {{'Shared.Passwords_arent_equal' | translate}}
            </div>
          </div>

          &lt;!&ndash;<div class="input-wrapper phone">
            <label for="phone">{{ 'Create_edit_tax_advisors_manager.Phone' | translate }}</label>
            <input type="tel" class="form-control" name="phone" id="phone" [(ngModel)]="newTaxAdvisorsManager.phone" #phone="ngModel" required/>
            <div *ngIf="f.submitted && !phone.valid" class="form-error-block help-block">
              {{'Shared.Field_is_required' | translate}}
            </div>
          </div>&ndash;&gt;
        </div>
      </form>
    </div>-->
  </div>
</div>
