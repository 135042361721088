import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-decision-tree',
  templateUrl: './decision-tree.component.html',
  styleUrls: ['./decision-tree.component.less']
})
export class DecisionTreeComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}
