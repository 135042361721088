export interface Node {
  id: number;
  name: string;
  name2: string;
  parent_id: number;
  next_question_id: number;
  edit: boolean;
  type: string;
  registration: boolean;
  tree_end: boolean;
  multi_answer: boolean;
  attributes: NodeAttribute[];
  children: Node[];
}

export interface NodeAttribute {
  description: string;
  id: number;
}

export class Nodes {
  constructor(
    public id: number,
    public name: string,
    public name2: string,
    public parent_id: number,
    public next_question_id: number,
    public edit: boolean,
    public type: string,
    public registration: boolean,
    public tree_end: boolean,
    public multi_answer: boolean,
    public attributes: NodeAttribute[],
    public children: Nodes[],
  ) {
    this.id = id;
    this.name = name;
    this.name2 = name2;
    this.parent_id = parent_id;
    this.next_question_id = next_question_id;
    this.edit = edit;
    this.type = type;
    this.registration = registration;
    this.tree_end = tree_end;
    this.multi_answer = tree_end;
    this.attributes = attributes;
    this.children = children;
  }
}
