import { Component, OnInit, TemplateRef } from '@angular/core';
import { SettingsService, CategoryService, SharedService } from "../../_services/index";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { BsModalService } from 'ngx-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NgSelectComponent } from "@ng-select/ng-select";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-user-attributes',
  templateUrl: './user-attributes.component.html',
  styleUrls: ['./user-attributes.component.less']
})
export class UserAttributesComponent implements OnInit {
  
  lang: any = {active: localStorage.getItem('lang')};
  modalRef: BsModalRef;
  addAttributesForm: FormGroup;
  editAttributesForm: FormGroup;
  itemToEdit: any;
  attributesList: any[] = [];
  document_types: any = [];
  attributeTypes = [{
    id: 0,
    name: 'User_attribute_type_1'
  }, {
    id: 1,
    name: 'User_attribute_type_2'
  }, {
    id: 2,
    name: 'User_attribute_type_3'
  }];
  
  
  constructor(
    private _SettingsService: SettingsService,
    private _CategoriesService: CategoryService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private translate: TranslateService,
    private _sharedService: SharedService
  ) {
    this.getAttributes();
    this.getCategories();
  }

  ngOnInit() {
    this._sharedService.changeLanguageEvent
      .subscribe((res) => {
        this.lang.active = res;
        this.categoriesLangHandler();
      });
  }
  
  openAddAttrsModal(template: TemplateRef<any>) {
    this.addAttributesFormInit();
    this.modalRef = this.modalService.show(
      template,
      Object.assign( { 'class': 'addAttrModal' })
    );
  }
  opeEditAttrsModal(template: TemplateRef<any>, data?: any) {
    let attribute = data;
    attribute['doc_ids'] = [];
    attribute['sub_categories'].forEach(child_obj => {
      attribute['doc_ids'].push(child_obj.id);
    });
    this.editAttributesFormInit(attribute);
    this.modalRef = this.modalService.show(
      template,
      Object.assign( { 'class': 'addAttrModal' })
    );
  }
  
  public categoriesLangHandler(): void {
    this.document_types.forEach(i => {
      if (this.lang.active === 'en') {
        i['title_to_show'] = i['title'];
        i['category_title_to_show'] = i['category_title'];
      } else {
        for (let key of Object.keys(i['title_translation'])) {
          if (key.toLowerCase() === this.lang.active) {
            if (i['title_translation'][key] !== null) {
              i['title_to_show'] = i['title_translation'][key];
            } else {
              i['title_to_show'] = i['title'];
            }
          }
        }
        for (let key of Object.keys(i['category_title_translations'])) {
          // console.log(key + ': ' + i['title_translation'][key]);
          if (key.toLowerCase() === this.lang.active) {
            if (i['category_title_translations'][key] !== null) {
              i['category_title_to_show'] = i['category_title_translations'][key];
            } else {
              i['category_title_to_show'] = i['category_title'];
            }
          }
        }
      }
    });
    this.attributesList.forEach(i => {
      i.sub_categories.forEach(child_obj => {
        this.document_types.forEach(deepest_obj => {
          if (child_obj.id === deepest_obj.id) {
            child_obj['title_to_show'] = deepest_obj['title_to_show'];
          }
        });
      });
    });
  }
  
  public addAttributesFormInit(): void {
    this.addAttributesForm = this.fb.group({
      name: [ null, [ Validators.required ]],
      type: [ this.attributeTypes[0].id, [ Validators.required ] ],
      document_types_input: [ [], [] ]
    });
  }
  
  public editAttributesFormInit(i): void {
    this.itemToEdit = i;
    this.editAttributesForm = this.fb.group({
      name: [ i.description, [ Validators.required ]],
      type: [ i.attribute_type.id, [ Validators.required ]],
      document_types_input: [ i.doc_ids, [] ]
    });
  }
  
  public getAttributes(): void {
    this._SettingsService.getAttributes()
      .subscribe(res => {
        this.attributesList = res.result;
        for (let t = 0; t < this.attributesList.length; t++) {
          this.attributesList[t]['attribute_type'] = {
            id: this.attributesList[t].type
          };
          if (this.attributesList[t].type === 0) {
            this.attributesList[t].attribute_type.name = this.attributeTypes[0].name;
          } else if (this.attributesList[t].type === 1) {
            this.attributesList[t].attribute_type.name = this.attributeTypes[1].name;
          } else if (this.attributesList[t].type === 2) {
            this.attributesList[t].attribute_type.name = this.attributeTypes[2].name;
          }
  
          for (let tt = 0; tt < this.attributesList[t].sub_categories.length; tt++) {
            if (tt <= 2) {
              this.attributesList[t]['show_all_sub'] = false;
              this.attributesList[t].sub_categories[tt]['show'] = true;
            } else {
              this.attributesList[t].sub_categories[tt]['show'] = false;
            }
          }
        }
        this.categoriesLangHandler();
      }, err => {
        console.log(err);
      });
  }
  
  public toggleCategories(i): any {
    if (!i.show_all_sub) {
      for (let t = 0; t < i.sub_categories.length; t++) {
        i.sub_categories[t]['show'] = true;
      }
    } else {
      for (let t = 0; t < i.sub_categories.length; t++) {
        (t <= 2) ? i.sub_categories[t]['show'] = true : i.sub_categories[t]['show'] = false;
      }
    }
    i.show_all_sub = !i.show_all_sub;
  }
  
  public getCategories(): void {
    this._CategoriesService.getCategoriesAll()
      .subscribe(res => {
        this.document_types = [];
        res.categories.forEach(obj => {
          if (obj.is_active) {
            obj.subCategories.forEach(child_obj => {
              child_obj['category_title'] = obj.title;
              child_obj['category_title_translations'] = obj.title_translation;
              if (child_obj.is_active) {
                this.document_types.push(child_obj);
              }
            });
          }
        });
        this.categoriesLangHandler();
      }, err => {
        console.log(err);
      });
    
  }
  
  public addNewAttribute(): void {
    let attribute = {
      description: this.addAttributesForm.value.name,
      type: parseInt(this.addAttributesForm.value.type, 10),
      sub_categories_id: this.addAttributesForm.value.document_types_input
    };
    this._SettingsService.addNewAttr(attribute)
      .subscribe(res => {
        console.log(res);
        this.modalRef.hide();
        this.getAttributes();
      }, err => {
        console.log(err);
      });
  }
  public editAttribute(i): void {
    let attribute = {
      attribute_id: i.id,
      description: this.editAttributesForm.value.name,
      type: parseInt(this.editAttributesForm.value.type, 10),
      sub_categories_id: this.editAttributesForm.value.document_types_input
    };
    
    this._SettingsService.addNewAttr(attribute)
      .subscribe(res => {
        console.log(res);
        this.modalRef.hide();
        this.getAttributes();
      }, err => {
        console.log(err);
      });
  }
  public deleteAttribute(i): void {
    let q = confirm('Are you sure?');
    if (q) {
      this._SettingsService.deleteAttr(i)
        .subscribe(res => {
          this.getAttributes();
        }, err => {
          console.log(err);
        });
    }
  }
  
  closeSelect(select: NgSelectComponent) {
    select.close();
  }
  openSelect(select: NgSelectComponent) {
    select.open();
  }
  clearAll1() {
    let translated_msg;
    this.translate.get('Alert.Are_you_sure_question')
      .subscribe((res: Object) => {
        translated_msg = res;
        let q = confirm(translated_msg);
        if (q) {
          this.addAttributesForm.value['document_types_input'] = [];
          this.addAttributesForm.controls['document_types_input'].setValue(this.addAttributesForm.value['document_types_input']);
        }
      });
  }
  clearAll() {
    let translated_msg;
    this.translate.get('Alert.Are_you_sure_question')
      .subscribe((res: Object) => {
        translated_msg = res;
        let q = confirm(translated_msg);
        if (q) {
          this.editAttributesForm.value['document_types_input'] = [];
          this.editAttributesForm.controls['document_types_input'].setValue(this.editAttributesForm.value['document_types_input']);
        }
      });
  }
  
}
