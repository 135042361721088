export class Category {
  constructor(
    public category_title: string,
    public cat_id: number,
    public sub_category: {
      title: string,
      description: string,
      sub_id: number
    }
  ) {}
}
