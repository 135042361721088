import { Component, OnInit } from '@angular/core';
import { TaxAdvisorsService, AlertService } from "../../_services/index";
import { ActivatedRoute, Router } from "@angular/router";
import { TaxAdvisorManager } from "../../_models/index";

import { FormGroup, FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: 'app-edit-taxadvisors-manager',
  templateUrl: './edit-taxadvisors-manager.component.html',
  styleUrls: ['./edit-taxadvisors-manager.component.less']
})
export class EditTaxAdvisorsManagerComponent implements OnInit {
  
  taxAdvisorManagerForm: FormGroup;
  
  taxAdvisorId = this.route.snapshot.params['id'];
  taxAdvisorManagerId = this.route.snapshot.params['manager_id'];
  
  newTaxAdvisorsManager = new TaxAdvisorManager();
  
  changePwd = false;
  
  back_url: any = localStorage.getItem('back_url');
  
  constructor(
    private _taxAdvisorsService: TaxAdvisorsService,
    private router: Router,
    private route: ActivatedRoute,
    private _alertService: AlertService,
    private fb: FormBuilder
  ) {
    this.getSingleTaxAdvisorsManager();
  }

  ngOnInit() {}
  
  public getSingleTaxAdvisorsManager(): void {
    this._taxAdvisorsService.getSingleTaxAdvisorManagerData(this.taxAdvisorManagerId)
      .subscribe(res => {
        console.log(res);
        this.newTaxAdvisorsManager = res.manager[0];
        console.log(this.newTaxAdvisorsManager);
        this.createForm();
      }, error => {
        console.log(error);
      });
  }
  
  createForm(): void {
    const emailPattern = "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?";
    const phonePattern = "^\\s*(?:\\+?\\d{1,4})?[- (]*\\d{4}(?:[- )]*\\d{4})?[- ]*\\d{5}(?: *[x/#]\\d+)?\\s*$";
    this.taxAdvisorManagerForm = this.fb.group({
      name: [this.newTaxAdvisorsManager.name, [Validators.required, Validators.maxLength(50)]],
      email: [this.newTaxAdvisorsManager.email, [Validators.required, Validators.pattern(emailPattern), Validators.maxLength(40)]],
      phone_number: [this.newTaxAdvisorsManager.phone_number, [Validators.required, Validators.pattern(phonePattern)]],
      password: ['', []],
      password_confirmation: ['', []]
    });
  }
  
  public addPwdValidation(): void {
    if (!this.changePwd) {
      this.changePwd = true;
      console.log(this.taxAdvisorManagerForm.controls);
      this.taxAdvisorManagerForm.controls['password'].setValidators([Validators.minLength(6), Validators.required]);
      this.taxAdvisorManagerForm.controls['password_confirmation'].setValidators([Validators.minLength(6), Validators.required]);
      this.taxAdvisorManagerForm.controls['password'].updateValueAndValidity();
      this.taxAdvisorManagerForm.controls['password_confirmation'].updateValueAndValidity();
    } else {
      this.changePwd = false;
      console.log(this.taxAdvisorManagerForm.controls);
      this.taxAdvisorManagerForm.controls['password'].setValue(null);
      this.taxAdvisorManagerForm.controls['password'].clearValidators();
      this.taxAdvisorManagerForm.controls['password_confirmation'].clearValidators();
      this.taxAdvisorManagerForm.controls['password'].updateValueAndValidity();
      this.taxAdvisorManagerForm.controls['password_confirmation'].updateValueAndValidity();
    }
  }
  
  public editTaxAdvisorsManager(): void {
    const taxAdviserData = this.taxAdvisorManagerForm.value;
    this._taxAdvisorsService.editTaxAdvisorsManager(taxAdviserData, this.taxAdvisorManagerId, this.taxAdvisorId, this.newTaxAdvisorsManager.is_active)
      .subscribe(res => {
        console.log(res);
        this._alertService.success('Alert.You_successfully_edited_taxadvisors_manager_information');
        this.router.navigate([`/taxadvisors/${this.taxAdvisorId}/manager/${this.taxAdvisorManagerId}`]);
      }, error => {
        console.log(error);
      });
  }

}
