<ngx-loading-bar></ngx-loading-bar>

<app-header></app-header>
<app-alert></app-alert>

<main [class.decision-tree]="currentRoute === '/decision-tree/tree-view'">
  <router-outlet></router-outlet>
</main>

<app-footer *ngIf="currentRoute !== '/decision-tree/tree-view'"></app-footer>
