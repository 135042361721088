import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import {EnvService} from "./env.service";
// import { environment } from "../../environments/environment";


@Injectable()
export class UserCreationService {
  // private API_BASE = environment.apiBase;
  
  constructor (
    private env: EnvService,
    private http: HttpClient
  ) {}
  
  public createNewUser<Object>(newUser, company_id): Observable<any> {
    
    const url = `${this.env.apiBase}/manager`;
    
    return this.http.post<Object>(url, {
      name: newUser.username,
      email: newUser.email,
      password: newUser.password,
      company_id: company_id
    });
  }
  
}
