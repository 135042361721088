import { Component, OnInit, ViewEncapsulation, TemplateRef } from '@angular/core';
import { TaxAdvisorsService, AlertService, SharedService, EnvService } from "../../_services/index";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { BsModalService } from 'ngx-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import * as moment_ from 'moment';

@Component({
  selector: 'app-single-taxadvisors-manager',
  templateUrl: './single-taxadvisors-manager.component.html',
  styleUrls: ['./single-taxadvisors-manager.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class SingleTaxadvisorsManagerComponent implements OnInit {
  
  modalRef: BsModalRef;
  timeTrackerFiltersForm: FormGroup;
  actionLogFiltersForm: FormGroup;
  managerRateForm: FormGroup;
  actionLogUsers: any[] = [];
  taxAdvisorManagerId;
  taxAdvisorId;
  taxAdvisorManagerData;
  managerTimeLogs;
  managerActionLogs;
  detailedTimeLog;
  tab = 1;
  work_statuses = [{
    id: 0,
    name: '',
    translated_name: 'Shared.Mobile_user_work_status_0'
  }, {
    id: 1,
    name: '',
    translated_name: 'Shared.Mobile_user_work_status_1'
  }, {
    id: 2,
    name: '',
    translated_name: 'Shared.Mobile_user_work_status_2'
  }, {
    id: 3,
    name: '',
    translated_name: 'Shared.Mobile_user_work_status_3'
  }, {
    id: 4,
    name: '',
    translated_name: 'Shared.Mobile_user_work_status_4'
  }];
  pConfig1 = {
    itemsPerPage: 5,
    currentPage: 1,
    sortField: 'updated_at',
    reverseOrder: false,
    search: '',
    totalItems: 0
  };
  pConfig2 = {
    itemsPerPage: 5,
    currentPage: 1,
    sortField: '',
    reverseOrder: false,
    search: '',
    totalItems: 0
  };
  pConfig3 = {
    itemsPerPage: 20,
    currentPage: 1,
    sortField: 'log_date',
    reverseOrder: false,
    search: '',
    totalItems: 0
  };
  
  env: string;
  lang: any = {
    active: localStorage.getItem('lang')
  };
  
  constructor(
    private _taxAdvisorsService: TaxAdvisorsService,
    private router: Router,
    private route: ActivatedRoute,
    private _alertService: AlertService,
    private _sharedService: SharedService,
    private translate: TranslateService,
    private fb: FormBuilder,
    private _env: EnvService,
    private modalService: BsModalService
  ) {
    this.route.params.subscribe(params => {
      this.taxAdvisorManagerId = params['manager_id'];
      this.taxAdvisorId = params['id'];
    });
    this.env = _env.env;
  }

  ngOnInit() {
    this.getSingleTaxAdvisorsManager();
    this.timeTrackerFiltersFormInit();
    this.actionLogFiltersFormInit();
    this._sharedService.changeLanguageEvent
      .subscribe((res) => {
        this.lang.active = res;
        this.changeStatusLang();
      });
  }
  
  changeStatusLang(): void {
    this.translate.get(['Shared.Mobile_user_work_status_0', 'Shared.Mobile_user_work_status_1', 'Shared.Mobile_user_work_status_2', 'Shared.Mobile_user_work_status_3', 'Shared.Mobile_user_work_status_4'])
      .subscribe(response => {
        Object.entries(response).forEach(
          ([key, value]) => {
            this.work_statuses.forEach(obj => {
              if (obj.translated_name === key) {
                obj.name = value.toString();
              }
              if (this.managerActionLogs) {
                this.managerActionLogs.forEach(child_obj => {
                  if (child_obj.value === obj.id) {
                    child_obj.value_name = obj.name;
                  }
                });
              }
            });
          }
        );
        this.work_statuses = [...this.work_statuses];
      });
  }
  
  openDetailedTimelog(template: TemplateRef<any>, data?: any) {
    this.pConfig3 = {
      itemsPerPage: 20,
      currentPage: 1,
      sortField: 'log_date',
      reverseOrder: false,
      search: '',
      totalItems: 0
    };
    if (data['log_work']) {
      data['log_work'].forEach(obj => {
        obj['show_details'] = false;
      });
    }
    this.detailedTimeLog = data;
    this.modalRef = this.modalService.show(
      template,
      Object.assign( { 'class': 'detailedTimelog' })
    );
  }
  
  public timeTrackerFiltersFormInit(): void {
    this.timeTrackerFiltersForm = this.fb.group({
      dateRange: [ [new Date(2019 , 0 , 1), new Date()], [] ],
      statusFilter: [ null, [] ]
    });
  }
  public timeTrackerFiltersFormSubmit(e?): void {
    if (e !== undefined) {
      this.timeTrackerFiltersForm.value.dateRange = e;
    }
    this.getSingleManagerTimeLogged(this.timeTrackerFiltersForm.value.dateRange, this.timeTrackerFiltersForm.value.statusFilter);
  }
  
  public actionLogFiltersFormInit(): void {
    this.actionLogFiltersForm = this.fb.group({
      dateRange: [ [new Date(2019 , 0 , 1), new Date()], [] ],
      usersFilter: [ null, [] ]
    });
  }
  public actionLogFiltersFormSubmit(e?): void {
    if (e !== undefined) {
      this.actionLogFiltersForm.value.dateRange = e;
    }
    this.getSingleManagerLogs(this.actionLogFiltersForm.value.dateRange, this.actionLogFiltersForm.value.usersFilter);
  }
  
  public managerRateFormInit(rate): void {
    this.managerRateForm = this.fb.group({
      rate: [ rate, [Validators.required, Validators.min(0)] ],
    });
  }
  public setupManagerRate(): void {
    this.taxAdvisorManagerData['rate'] = this.managerRateForm.value.rate;
    this._taxAdvisorsService.setupManagerWorkRate(this.taxAdvisorManagerData)
      .subscribe(res => {
        this.taxAdvisorManagerData['rate_edit'] = false;
      }, err => {
        console.log(err);
      });
  }
  
  sortBy1 = function(sortField) {
    this.pConfig1['reverseOrder'] = (this.pConfig1['sortField'] === sortField) ? !this.pConfig1['reverseOrder'] : false;
    this.pConfig1['sortField'] = sortField;
    this.getSingleManagerLogs(this.actionLogFiltersForm.value.dateRange, this.actionLogFiltersForm.value.usersFilter);
  };
  sortBy2 = function(sortField) {
    this.pConfig2['reverseOrder'] = (this.pConfig2['sortField'] === sortField) ? !this.pConfig2['reverseOrder'] : false;
    this.pConfig2['sortField'] = sortField;
  };
  sortBy3 = function(sortField) {
    this.pConfig3['reverseOrder'] = (this.pConfig3['sortField'] === sortField) ? !this.pConfig3['reverseOrder'] : false;
    this.pConfig3['sortField'] = sortField;
  };
  pageChange1(event: any): void {
    this.pConfig1['currentPage'] = event;
    this.getSingleManagerLogs(this.actionLogFiltersForm.value.dateRange, this.actionLogFiltersForm.value.usersFilter);
  }
  pageChange2(event: any): void {
    this.pConfig2['currentPage'] = event;
  }
  pageChange3(event: any): void {
    this.pConfig3['currentPage'] = event;
  }
  perPageChange1(num: number): void {
    this.pConfig1['itemsPerPage'] = num;
    this.getSingleManagerLogs(this.actionLogFiltersForm.value.dateRange, this.actionLogFiltersForm.value.usersFilter);
  }
  tabHandler(pageId: number): void {
    this.tab = pageId;
  }
  
  public getSingleTaxAdvisorsManager(): void {
    this._taxAdvisorsService.getSingleTaxAdvisorManagerData(this.taxAdvisorManagerId)
      .subscribe(res => {
        this.taxAdvisorManagerData = res.manager[0];
        /*this.taxAdvisorManagerData.users.forEach(obj => {
          obj['show_details'] = false;
          obj['name_concat'] = obj['name'] + ' ' + obj['second_name'];
          switch (obj['status']) {
            case 0:
              obj['status_name'] = 'Shared.Mobile_user_work_status_0';
              break;
            case 1:
              obj['status_name'] = 'Shared.Mobile_user_work_status_1';
              break;
            case 2:
              obj['status_name'] = 'Shared.Mobile_user_work_status_2';
              break;
            case 3:
              obj['status_name'] = 'Shared.Mobile_user_work_status_3';
              break;
            case 4:
              obj['status_name'] = 'Shared.Mobile_user_work_status_4';
              break;
          }
        });*/
        if (!this.taxAdvisorManagerData['rate']) {
          this.taxAdvisorManagerData['rate'] = 0;
        }
        this.taxAdvisorManagerData['rate_edit'] = false;
        this.managerRateFormInit(this.taxAdvisorManagerData['rate']);
        // this.getSingleManagerLogs(this.actionLogFiltersForm.value);
      }, error => {
        console.log(error);
      });
  }
  public getSingleManagerTimeLogged(date, status): void {
    let converted_date_start = moment_(date[0], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");
    let converted_date_end = moment_(date[1], "YYYY-MM-DD HH:mm:ss").hour(23).minute(59).second(59).format("YYYY-MM-DD HH:mm:ss");
    console.log({
      manager_id: this.taxAdvisorManagerId,
      status: (status === null) ? '' : status,
      date_start: converted_date_start,
      date_end: converted_date_end
    });
    this._taxAdvisorsService.getTaxAdvisorManagerLoggedTime({
      manager_id: this.taxAdvisorManagerId,
      status: (status === null) ? '' : status,
      date_start: converted_date_start,
      date_end: converted_date_end
    }).subscribe(res => {
      this.managerTimeLogs = res.result[0];
      if (!this.managerTimeLogs['users']) {
        this.managerTimeLogs['users'] = [];
      } else {
        this.managerTimeLogs['users'].forEach(obj => {
          obj['show_details'] = false;
          obj['name_concat'] = obj['name'] + ' ' + obj['second_name'];
          switch (obj['status']) {
            case 0:
              obj['status_name'] = 'Shared.Mobile_user_work_status_0';
              break;
            case 1:
              obj['status_name'] = 'Shared.Mobile_user_work_status_1';
              break;
            case 2:
              obj['status_name'] = 'Shared.Mobile_user_work_status_2';
              break;
            case 3:
              obj['status_name'] = 'Shared.Mobile_user_work_status_3';
              break;
            case 4:
              obj['status_name'] = 'Shared.Mobile_user_work_status_4';
              break;
          }
        });
      }
      console.log(res.result[0]);
    }, err => {
      console.log(err);
    });
  }
  
  public getSingleManagerLogs(date, user_id): void {
    let converted_date_start = moment_(date[0], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");
    let converted_date_end = moment_(date[1], "YYYY-MM-DD HH:mm:ss").hour(23).minute(59).second(59).format("YYYY-MM-DD HH:mm:ss");
    /*console.log(date);
    console.log({
      manager_id: this.taxAdvisorManagerId,
      page: this.pConfig1.currentPage,
      per_page: this.pConfig1.itemsPerPage,
      sort: this.pConfig1.reverseOrder ? 'desc' : 'asc',
      order_by: this.pConfig1.sortField,
      date_start: converted_date_start,
      date_end: converted_date_end,
      user_id: (!user_id) ? '' : user_id
    });*/
    
    this._taxAdvisorsService.getActionLogsByManager({
      manager_id: this.taxAdvisorManagerId,
      page: this.pConfig1.currentPage,
      per_page: this.pConfig1.itemsPerPage,
      sort: this.pConfig1.reverseOrder ? 'desc' : 'asc',
      order_by: this.pConfig1.sortField,
      date_start: converted_date_start,
      date_end: converted_date_end,
      user_id: (!user_id) ? '' : user_id
    }).subscribe(res => {
      this.pConfig1['totalItems'] = res.count;
      res.result.forEach(obj => {
        obj.action_name = 'Log_status_change';
      });
      this.managerActionLogs = res.result;
      this.changeStatusLang();
    }, err => {
      console.log(err);
    });
  }
  
  public editTaxAdvisorManager(): void {
    this.router.navigate([`taxadvisors/${this.taxAdvisorId}/manager/${this.taxAdvisorManagerId}/edit`]);
  }
  
}
