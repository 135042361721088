<div class="oc-h-bar oc-border" *ngIf="hasParent"></div>
<div class="node-content-wrapper">
  <div class="oc-box oc-border oc-background node-body" [class]="nodes.type" [ngClass]="{'tree-end': nodes.tree_end}">
    <div class="oc-name" [ngClass]="{'has-attributes': nodes.attributes.length}">
      {{nodes?.name}}
    </div>
    <div class="oc-name" *ngIf="nodes.tree_end" [ngClass]="{'has-attributes': nodes.attributes.length}">
      {{nodes?.name2}}
    </div>
    <i class="fas fa-sign-in-alt registration_needed" *ngIf="nodes.registration"></i>
    <i class="fas fa-list-ul multi_answer" *ngIf="nodes.multi_answer"></i>
    <div class="attributes">
      <span *ngFor="let q of nodes.attributes">{{q.description}}</span>
    </div>
  </div>
  <span class="node-controls">
    <i class="fas fa-pencil-alt edit_node" (click)="openEditNodeModal(editNodeModal, nodes)"></i>
    <i *ngIf="nodes.parent_id" (click)="deleteNode(nodes)" class="far far fa-trash-alt delete_node"></i>
  </span>
</div>
<i *ngIf="nodes.type === 'question' && !nodes.tree_end || nodes.children.length <= 0 && nodes.type === 'answer'" (click)="openAddNodeModal(addNodeModal, nodes)" class="far fa-plus-square add_node"></i>
<div class="oc-h-bar oc-border" *ngIf="nodes?.children.length"></div>


<ng-template #editNodeModal>
  <div class="modal-header">
    <h5 class="modal-title pull-left">{{nodes.name}}</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form name="editNodeForm" id="editNodeForm" class="editNodeForm" (ngSubmit)="editNodeForm.valid && editNode(nodes)" [formGroup]="editNodeForm" #f="ngForm" [ngClass]="{ 'ng-submitted-error': f.submitted && f.form.invalid }" novalidate>
      <div class="input-wrapper name">
        <label for="edit_name1">{{ 'Decision_tree.Main_text' | translate}}</label>
        <textarea class="form-control" name="edit_name1" id="edit_name1" formControlName="name1"></textarea>
      </div>
      <div class="input-wrapper name2" *ngIf="nodes.tree_end">
        <label for="edit_name2">{{ 'Decision_tree.Secondary_text' | translate}}</label>
        <textarea class="form-control" name="edit_name2" id="edit_name2" formControlName="name2"></textarea>
      </div>

      <div class="input-wrapper attrs" *ngIf="nodes.type === 'answer'">
        <label>{{ 'Decision_tree.User_attributes' | translate}}</label>
        <ng-select [items]="attributes"
                   bindLabel="description"
                   labelForId="id"
                   #select
                   [multiple]="true"
                   appendTo=".input-wrapper.attrs"
                   [notFoundText]="'Mobile_users.No_attributes_found' | translate"
                   [placeholder]="'Decision_tree.Select_attributes' | translate"
                   [closeOnSelect]="false"
                   [hideSelected]="false"
                   [searchable]="true"
                   formControlName="attributes1">
          <ng-template ng-footer-tmp>
            <button class="main-action-btn no-icon-btn" (click)="closeSelect(select)">
              {{'Mobile_users.Confirm_selected_attributes_btn' | translate}}
            </button>
          </ng-template>
        </ng-select>
        <div class="ng-select-controls" [class.hide_clear_all]="!editNodeForm.value.attributes1.length">
          <button class="cancel-form-btn no-icon-btn clear_all_documents_btn" type="button" (click)="clearAll()">
            {{'Mobile_users.Clear_all_attributes_btn' | translate}}
          </button>
          <button class="main-action-btn no-icon-btn add_more_documents_btn" type="button" (click)="openSelect(select)">
            {{'Mobile_users.Add_more_attributes_btn' | translate}}
          </button>
        </div>
      </div>

      <div class="input-wrapper registration" *ngIf="nodes.type === 'answer'">
        <label for="registration_break_point1">{{ "Decision_tree.Registration_needed" | translate }}</label>
        <input class="registration_break_point1" id="registration_break_point1" name="registration_break_point1" formControlName="registration1" type="checkbox" [checked]="nodes.registration">
      </div>
      <div class="input-wrapper multi_answer" *ngIf="nodes.type === 'question' && !nodes.tree_end">
        <label for="multi_answer1">{{ "Decision_tree.Multi_answer" | translate }}</label>
        <input class="multi_answer1" id="multi_answer1" name="multi_answer1" formControlName="multi_answer1" type="checkbox" [checked]="nodes.multi_answer">
      </div>
      <div class="input-wrapper tree_end" *ngIf="nodes.type === 'question' && nodes.children.length <= 0">
        <label for="tree_end1">{{ "Decision_tree.Is_this_the_end_of_this_tree_branch" | translate }}</label>
        <input class="tree_end1" id="tree_end1" name="tree_end1" formControlName="tree_end1" type="checkbox" [checked]="nodes.tree_end">
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="modal-submit-btn no-icon-btn" form="editNodeForm">
      <span>{{ 'Shared.Save' | translate }}</span>
    </button>
    <button class="modal-cancel-btn no-icon-btn" type="button" (click)="modalRef.hide()">
      <span>{{ 'Shared.Close_modal' | translate }}</span>
    </button>
  </div>
</ng-template>

<ng-template #addNodeModal>
  <div class="modal-header">
    <h5 class="modal-title pull-left">{{ 'Decision_tree.New_node_modal_title' | translate }}</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form name="addNodeForm" id="addNodeForm" class="addNodeForm" (ngSubmit)="addNodeForm.valid && addNode(nodes)" [formGroup]="addNodeForm" #f="ngForm" [ngClass]="{ 'ng-submitted-error': f.submitted && f.form.invalid }" novalidate>
      <div class="input-wrapper name">
        <label for="add_name1">{{ 'Decision_tree.Main_text' | translate}}</label>
        <textarea class="form-control" name="add_name1" id="add_name1" formControlName="name1"></textarea>
      </div>
      <div class="input-wrapper name" *ngIf="nodes.tree_end">
        <label for="add_name2">{{ 'Decision_tree.Secondary_text' | translate}}</label>
        <textarea class="form-control" name="add_name2" id="add_name2" formControlName="name2"></textarea>
      </div>
      <div class="input-wrapper attrs" *ngIf="nodes.type !== 'answer'">
        <label>{{ 'Decision_tree.User_attributes' | translate}}</label>
        <ng-select [items]="attributes"
                   bindLabel="description"
                   labelForId="id"
                   #select1
                   [multiple]="true"
                   appendTo=".input-wrapper.attrs"
                   [placeholder]="'Decision_tree.Select_attributes' | translate"
                   [notFoundText]="'Mobile_users.No_attributes_found' | translate"
                   [closeOnSelect]="false"
                   [hideSelected]="false"
                   [searchable]="true"
                   formControlName="attributes2">
          <ng-template ng-footer-tmp>
            <button class="main-action-btn no-icon-btn" (click)="closeSelect(select1)">
              {{'Mobile_users.Confirm_selected_attributes_btn' | translate}}
            </button>
          </ng-template>
        </ng-select>
        <div class="ng-select-controls" [class.hide_clear_all]="!addNodeForm.value.attributes2.length">
          <button class="cancel-form-btn no-icon-btn clear_all_documents_btn" type="button" (click)="clearAll1()">
            {{'Mobile_users.Clear_all_attributes_btn' | translate}}
          </button>
          <button class="main-action-btn no-icon-btn add_more_documents_btn" type="button" (click)="openSelect(select1)">
            {{'Mobile_users.Add_more_attributes_btn' | translate}}
          </button>
        </div>
      </div>
      <div class="input-wrapper registration" *ngIf="nodes.type === 'question'">
        <label for="registration_break_point2">{{ "Decision_tree.Registration_needed" | translate }}</label>
        <input class="registration_break_point2" id="registration_break_point2" name="registration_break_point2" formControlName="registration2" type="checkbox" [checked]="nodes.registration">
      </div>
      <div class="input-wrapper multi_answer" *ngIf="nodes.type === 'answer'">
        <label for="multi_answer2">{{ "Decision_tree.Multi_answer" | translate }}</label>
        <input class="multi_answer2" id="multi_answer2" name="multi_answer2" formControlName="multi_answer2" type="checkbox" [checked]="nodes.multi_answer">
      </div>
      <div class="input-wrapper tree_end" *ngIf="nodes.type === 'answer'">
        <label for="tree_end2">{{ "Decision_tree.Is_this_the_end_of_this_tree_branch" | translate }}</label>
        <input class="tree_end2" id="tree_end2" name="tree_end2" formControlName="tree_end2" type="checkbox" [checked]="nodes.tree_end">
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="modal-submit-btn no-icon-btn" form="addNodeForm">
      <span>{{ 'Shared.Save' | translate }}</span>
    </button>
    <button class="modal-cancel-btn no-icon-btn" type="button" (click)="modalRef.hide()">
      <span>{{ 'Shared.Close_modal' | translate }}</span>
    </button>
  </div>
</ng-template>
