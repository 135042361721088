import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { EnvService } from "./env.service";
import * as AWS from 'aws-sdk';
import { DomSanitizer } from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class AwsHandlerService {
  
  s3: any;
  env: any;
  
  constructor(
    private _env: EnvService,
    private _sanitizer: DomSanitizer
  ) {
    this.env = _env;
  }
  
  public handleAWSimages(data): any {
    return new Observable(observer => {
      data['relevantSubcategories'].forEach(obj => {
        obj['docs'].forEach(child_obj => {
          child_obj['document_items_blobs'] = [];
        });
      });
      
      data['relevantSubcategories'].forEach(obj => {
        obj['docs'].forEach(child_obj => {
          child_obj['document_items'].forEach(deepest_obj => {
            let pathToImage = deepest_obj.split("amazonaws.com/").pop();
            this.s3 = new AWS.S3({
              region: this.env.region,
              signatureVersion: this.env.signatureVersion,
              accessKeyId: this.env.accessKeyId,
              secretAccessKey: this.env.secretAccessKey
            });
            let params = {
              Bucket: this.env.Bucket,
              Key: pathToImage
            };
            this.s3.getObject(params, function (err, data1) {
              return data1;
            }).promise()
              .then(res => {
                let url = window.URL;
                let blob = new Blob([res.Body], {type: "image/jpeg"});
                let trusted_blob = this._sanitizer.bypassSecurityTrustResourceUrl(url.createObjectURL(blob));
                child_obj['document_items_blobs'].push(trusted_blob);
              }, err => {
                console.log(err);
              });
          });
        });
      });
      
      setTimeout(function () {
        observer.next(data);
        // observer.complete();
      }, 2000);
    });
  }
  
}
