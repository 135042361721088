<div class="tax-advisors-managers-page-tpl container">
  <div class="row">
    <div class="main-content col-12">
      <div class="top-bar">
        <div class="breadcrumbs">
          <span class="breadcrumb-item">
            <a [routerLink]="['/taxadvisors']">{{ 'Shared.Tax_advisors' | translate }}</a>
          </span>
          <span class="breadcrumb-item" *ngIf="taxAdvisor">{{taxAdvisor.company_name}}</span>
        </div>
        <div class="controls">
          <button class="main-action-btn no-icon-btn" *ngIf="tab === 1" (click)="editTaxAdvisorInfo()">
            <span>{{ 'Tax_advisors.Edit_information' | translate }}</span>
          </button>
          <button class="main-action-btn no-icon-btn" *ngIf="tab === 2" (click)="addTaxAdvisorManager()">
            <span>{{ 'Tax_advisors.Add_specialist' | translate }}</span>
          </button>
        </div>
      </div>
      <div class="vertical-tabs-wrapper-unified">
        <div class="sidebar vertical-tabs">
          <ul class="tab-navigation">
            <li [class.active]="tab === 1">
              <a (click)="tabHandler(1)" target="_self">
                <span class="tax-advisors-tab-title">{{ 'Tax_advisors.General_information' | translate }}</span>
              </a>
            </li>
            <li [class.active]="tab === 2">
              <a (click)="tabHandler(2)" target="_self">
                <span class="tax-advisors-specialists-tab-title">{{ 'Tax_advisors.Specialists' | translate }}</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="tab-content">
          <div id="taxadvisor-data" class="tab" [class.active]="tab === 1">
            <div class="tax-advisor-info-wrapper" *ngIf="taxAdvisor">
              <table class="tax-advisor-info-table">
                <tbody>
                <tr>
                  <td class="td-title">{{ 'Tax_advisors.Legal_name' | translate }}:</td>
                  <td class="td-content">{{taxAdvisor.company_name}}</td>
                </tr>
                <tr>
                  <td class="td-title">{{ 'Tax_advisors.Name' | translate }}:</td>
                  <td class="td-content">{{taxAdvisor.name}}</td>
                </tr>
                <tr>
                  <td class="td-title">{{ 'Tax_advisors.Contact_person' | translate }}:</td>
                  <td class="td-content">{{taxAdvisor.contact_person}}</td>
                </tr>
                <tr>
                  <td class="td-title">{{ 'Tax_advisors.Email' | translate }}:</td>
                  <td class="td-content">{{taxAdvisor.email}}</td>
                </tr>
                <tr>
                  <td class="td-title">{{ 'Tax_advisors.Phone' | translate }}:</td>
                  <td class="td-content">{{taxAdvisor.phone_number}}</td>
                </tr>
                <tr>
                  <td class="td-title">{{ 'Tax_advisors.Company_details' | translate }}:</td>
                  <td class="td-content pre-line-data">{{taxAdvisor.description}}</td>
                </tr>
                <tr class="is_active">
                  <td class="td-title">{{ 'Shared.Status' | translate }}:</td>
                  <td class="td-content">
                    <i [ngClass]="{'active': taxAdvisor.is_active === 1}" class="fas fa-circle"></i>
                  </td>
                </tr>
                <tr>
                  <td class="td-title">{{ 'Tax_advisors.Created_at' | translate }}:</td>
                  <td class="td-content">{{taxAdvisor.created_at}}</td>
                </tr>
                <tr>
                  <td class="td-title">{{ 'Tax_advisors.Updated_at' | translate }}:</td>
                  <td class="td-content">{{taxAdvisor.updated_at}}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div id="taxadvisor-managers" class="tab" [class.active]="tab === 2">
            <div class="tax-advisor-specialists-table-wrapper datatable-wrapper">
              <div class="filters" >
                <div class="search">
                  <label class="search-label" for="pConfig1.search">
                    <i class="fas fa-search"></i>
                  </label>
                  <input [placeholder]="'Shared.Type_to_filter' | translate" [(ngModel)]="pConfig1.search" id="pConfig1.search" name='search' class="doInput">
                </div>
                <div class="itemsPerPage">
                  <label>{{'Shared.Items_per_page' | translate}}:</label>
                  <div class="perPageIndicators">
                    <button [ngClass]="{'active': pConfig1.itemsPerPage === 1}" (click)="pConfig1.itemsPerPage = 1; pConfig1.currentPage = 0">1</button>
                    <button [ngClass]="{'active': pConfig1.itemsPerPage === 5}" (click)="pConfig1.itemsPerPage = 5; pConfig1.currentPage = 0">5</button>
                    <button [ngClass]="{'active': pConfig1.itemsPerPage === 10}" (click)="pConfig1.itemsPerPage = 10; pConfig1.currentPage = 0">10</button>
                    <button [ngClass]="{'active': pConfig1.itemsPerPage === 20}" (click)="pConfig1.itemsPerPage = 20; pConfig1.currentPage = 0">20</button>
                  </div>
                </div>
              </div>
              <div class="table-wrapper">
                <table class="table">
                  <thead>
                  <tr>
                    <th class="name" (click)="sortBy1('name')">
                      <span>{{'Tax_advisors.User_name' | translate}}</span>
                      <span class="sort_icons" [hidden]="pConfig1.sortField != 'name'">
                        <i class="fas fa-sort-down" [class.sort_active]="pConfig1.sortField == 'name' && pConfig1.reverseOrder == true"></i>
                        <i class="fas fa-sort-up" [class.sort_active]="pConfig1.sortField == 'name' && pConfig1.reverseOrder == false"></i>
                    </span>
                    </th>
                    <th class="email" (click)="sortBy1('email')">
                      <span>{{'Tax_advisors.User_email' | translate}}</span>
                      <span class="sort_icons" [hidden]="pConfig1.sortField != 'email'">
                        <i class="fas fa-sort-down" [class.sort_active]="pConfig1.sortField == 'email' && pConfig1.reverseOrder == true"></i>
                        <i class="fas fa-sort-up" [class.sort_active]="pConfig1.sortField == 'email' && pConfig1.reverseOrder == false"></i>
                    </span>
                    </th>
                    <th class="phone_number" (click)="sortBy1('phone_number')">
                      <span>{{'Tax_advisors.User_phone' | translate}}</span>
                      <span class="sort_icons" [hidden]="pConfig1.sortField != 'phone_number'">
                        <i class="fas fa-sort-down" [class.sort_active]="pConfig1.sortField == 'phone_number' && pConfig1.reverseOrder == true"></i>
                        <i class="fas fa-sort-up" [class.sort_active]="pConfig1.sortField == 'phone_number' && pConfig1.reverseOrder == false"></i>
                    </span>
                    </th>
                    <th class="manager_status" (click)="sortBy1('is_active')">
                      <span>{{'Tax_advisors.User_status' | translate}}</span>
                      <span class="sort_icons" [hidden]="pConfig1.sortField != 'is_active'">
                        <i class="fas fa-sort-down" [class.sort_active]="pConfig1.sortField == 'is_active' && pConfig1.reverseOrder == true"></i>
                        <i class="fas fa-sort-up" [class.sort_active]="pConfig1.sortField == 'is_active' && pConfig1.reverseOrder == false"></i>
                    </span>
                    </th>
                    <th class="created_at" (click)="sortBy1('created_at')">
                      <span>{{'Tax_advisors.Created_at' | translate}}</span>
                      <span class="sort_icons" [hidden]="pConfig1.sortField != 'created_at'">
                        <i class="fas fa-sort-down" [class.sort_active]="pConfig1.sortField == 'created_at' && pConfig1.reverseOrder == true"></i>
                        <i class="fas fa-sort-up" [class.sort_active]="pConfig1.sortField == 'created_at' && pConfig1.reverseOrder == false"></i>
                    </span>
                    </th>
                    <th class="updated_at" (click)="sortBy1('updated_at')">
                      <span>{{'Tax_advisors.Updated_at' | translate}}</span>
                      <span class="sort_icons" [hidden]="pConfig1.sortField != 'updated_at'">
                        <i class="fas fa-sort-down" [class.sort_active]="pConfig1.sortField == 'updated_at' && pConfig1.reverseOrder == true"></i>
                        <i class="fas fa-sort-up" [class.sort_active]="pConfig1.sortField == 'updated_at' && pConfig1.reverseOrder == false"></i>
                    </span>
                    </th>
                    <th class="edit"></th>
                    <th class="delete"></th>
                  </tr>
                  </thead>
                  <tbody *ngIf="taxAdvisorManagers">
                  <tr (click)="goToSingleTaxAdvisorManager(i)" class="hover_rows" *ngFor="let i of taxAdvisorManagers | filter: pConfig1.search :'name, email, phone_number, created_at, updated_at' | orderBy: pConfig1.sortField:pConfig1.reverseOrder | paginate: {
                    id: 'tax_advisor_managers_pagination',
                    itemsPerPage: pConfig1.itemsPerPage,
                    currentPage: pConfig1.currentPage
                   }">
                    <td>
                      {{i.name}}
                    </td>
                    <td>
                      {{i.email}}
                    </td>
                    <td>
                      {{i.phone_number}}
                    </td>
                    <td class="status_td">
                      <i [class.active]="i.is_active === 1" class="fas fa-circle"></i>
                    </td>
                    <td>
                      {{i.created_at}}
                    </td>
                    <td>
                      {{i.updated_at}}
                    </td>
                    <td class="action_td" (click)="$event.stopPropagation(); editTaxAdvisorManager(i)">
                      <i class="fas fa-pencil-alt"></i>
                    </td>
                    <td [class.activate]="!i.is_active" class="action_td" (click)="$event.stopPropagation(); deleteTaxAdvisorManager(i)">
                      <!--<i class="fas fa-trash"></i>-->
                      <i class="fas fa-power-off"></i>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div *ngIf="taxAdvisorManagers" class="table-footer">
                <div class="items-total-wrapper">
                <span translate [translateParams]="{ total_items: taxAdvisorManagers.length }">
                  Shared.Total_items_in_table
                </span>
                </div>
                <pagination-controls  id="tax_advisor_managers_pagination"
                                      class="table-pagination"
                                      (pageChange)="pageChange1($event)"
                                      maxSize="5"
                                      directionLinks="false"
                                      autoHide="true"
                                      responsive="false">
                </pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
