import { Component, OnInit } from '@angular/core';
import { TaxAdvisorsService, AlertService } from "../../_services/index";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: 'app-create-taxadvisors-manager',
  templateUrl: './create-taxadvisors-manager.component.html',
  styleUrls: ['./create-taxadvisors-manager.component.less']
})
export class CreateTaxAdvisorsManagerComponent implements OnInit {
  
  taxAdvisorManagerForm: FormGroup;
  taxAdvisorId = this.route.snapshot.params['id'];
  
  constructor(
    private _taxAdvisorsService: TaxAdvisorsService,
    private router: Router,
    private route: ActivatedRoute,
    private _alertService: AlertService,
    private fb: FormBuilder
  ) {
    this.createForm();
  }
  
  ngOnInit() { }
  
  createForm(): void {
    const emailPattern = "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?";
    const phonePattern = "^\\s*(?:\\+?\\d{1,4})?[- (]*\\d{4}(?:[- )]*\\d{4})?[- ]*\\d{5}(?: *[x/#]\\d+)?\\s*$";
    this.taxAdvisorManagerForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(50)]],
      email: ['', [Validators.required, Validators.pattern(emailPattern), Validators.maxLength(40)]],
      phone_number: ['', [Validators.required, Validators.pattern(phonePattern)]],
      password: ['', [Validators.minLength(6), Validators.required]],
      password_confirmation: ['', [Validators.minLength(6), Validators.required]]
    });
  }
  
  public createTaxAdvisorsManager(): void {
    console.log(this.taxAdvisorManagerForm.value);
    const taxAdviserData = this.taxAdvisorManagerForm.value;
    this._taxAdvisorsService.createTaxAdvisorsManager(taxAdviserData, this.taxAdvisorId)
      .subscribe(res => {
        console.log(res);
        this._alertService.success('Alert.You_successfully_created_new_taxadvisors_manager');
        this.router.navigate([`/taxadvisors/${this.taxAdvisorId}`]);
      }, error => {
        console.log(error);
      });
  }
}
