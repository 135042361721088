import { Component, OnInit } from '@angular/core';
import { TaxAdvisorsService, AlertService } from "../../_services/index";
import { ActivatedRoute, Router} from "@angular/router";

import { FormGroup, FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: 'app-edit-taxadvisor',
  templateUrl: './edit-taxadvisor.component.html',
  styleUrls: ['./edit-taxadvisor.component.less']
})
export class EditTaxAdvisorComponent implements OnInit {
  
  taxAdvisorForm: FormGroup;
  taxAdvisorData: any;
  taxAdvisorId: any = this.route.snapshot.params['id'];
  back_url: any = localStorage.getItem('back_url');
  
  constructor(
    private _taxAdvisorsService: TaxAdvisorsService,
    private router: Router,
    private _alertService: AlertService,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) {
    this.getSingleTaxAdvisorData();
  }
  
  ngOnInit() {}
  
  public getSingleTaxAdvisorData(): void {
    this._taxAdvisorsService.getSingleTaxAdvisorData({
      id: this.taxAdvisorId
    }).subscribe(res => {
      console.log(res);
      this.taxAdvisorData = res.tax_adviser;
      this.createForm();
    }, error => {
      console.log(error);
    });
  }
  
  createForm(): void {
    const emailPattern = "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?";
    const phonePattern = "^\\s*(?:\\+?\\d{1,4})?[- (]*\\d{4}(?:[- )]*\\d{4})?[- ]*\\d{5}(?: *[x/#]\\d+)?\\s*$";
    this.taxAdvisorForm = this.fb.group({
      name: [this.taxAdvisorData.name, [Validators.required, Validators.maxLength(50)]],
      legal_name: [this.taxAdvisorData.company_name, [Validators.required, Validators.maxLength(50)]],
      contact_person: [this.taxAdvisorData.contact_person, [Validators.required, Validators.maxLength(50)]],
      email: [this.taxAdvisorData.email, [Validators.required, Validators.pattern(emailPattern), Validators.maxLength(40)]],
      company_details: [this.taxAdvisorData.description, [Validators.required, Validators.maxLength(300)]],
      phone_number: [this.taxAdvisorData.phone_number, [Validators.required, Validators.pattern(phonePattern)]]
    });
  }
  
  public editTaxAdvisor(): void {
    const taxAdvisorData = this.taxAdvisorForm.value;
    console.log(taxAdvisorData);
    this._taxAdvisorsService.editTaxAdvisor(this.taxAdvisorId, taxAdvisorData)
      .subscribe(res => {
        console.log(res);
        this._alertService.success('Alert.You_successfully_edited_taxadvisor_information');
        // this.router.navigate([`/taxadvisors/${this.taxAdvisorId}`]);
        this.router.navigate([this.back_url]);
      }, error => {
        console.log(error);
      });
  }

}
