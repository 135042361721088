import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from "../_services/index";
import { TranslateService } from "@ngx-translate/core";
import { CategoriesListComponent } from "../categories/categories.component";
import { SharedService } from "../_services/shared.service";
import { Router, NavigationEnd } from "@angular/router";


@Component({
  providers: [ CategoriesListComponent ],
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {
  
  lang: any = {active: localStorage.getItem('lang')};
  user: any;
  
  constructor(
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
    private _SharedService: SharedService,
    private router: Router
  ) {
    this.lang['active'] = this.lang['active'] === null ? 'ru' : this.lang['active'];
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.user = JSON.parse(localStorage.getItem('currentUser'));
      }
    });
  }

  ngOnInit() {}
  
  public logout(): void {
    this.authenticationService.logout();
  }
  
  public useLanguage(lang): void {
    console.log(lang);
    this.lang.active = lang;
    this.translate.use(lang);
    this._SharedService.changeLanguageEvent.emit(lang);
  }
  
}
