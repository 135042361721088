<header>
  <div class="container">
    <div class="row">
      <div class="col col-12">
        <ul class="nav-menu">
          <li class="nav-item" *ngIf="user && user.role === 'operator'">
            <a [routerLink]="['/']">{{'Header.Categories' | translate}}</a>
          </li>
          <li class="nav-item" *ngIf="user && user.role === 'operator'">
            <a [routerLink]="['/taxadvisors']">{{'Header.Tax_advisors' | translate}}</a>
          </li>
          <li class="nav-item" *ngIf="user && (user.role === 'operator' || user.role === 'manager')">
            <a [routerLink]="['/mobile-users']">{{'Header.Mobile_users' | translate}}</a>
          </li>
          <li class="nav-item" *ngIf="user && user.role === 'operator'">
            <span dropdown>
              <a href id="basic-link" dropdownToggle (click)="false"
                 aria-controls="basic-link-dropdown">{{'Header.Decision_tree' | translate}}</a>
              <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu"
                  role="menu" aria-labelledby="basic-link">
                <li class="nav-item">
                  <a class="dropdown-item" [routerLink]="['/decision-tree/table-view']">
                    {{'Header.Table_view' | translate}}
                  </a>
                </li>
                <li class="nav-item">
                  <a class="dropdown-item" [routerLink]="['/decision-tree/tree-view']">
                     {{'Header.Tree_view' | translate}}
                  </a>
                </li>
              </ul>
            </span>
          </li>
        </ul>
        <ul class="nav-menu">
          <li class="nav-item settings" *ngIf="user && user.role === 'operator'">
            <a [routerLink]="['/settings']">
              <i class="fas fa-cog"></i>
              <span translate>{{'Header.Settings'}}</span>
            </a>
          </li>
          <li class="nav-item language">
            <div class="dropdown lang_dropdown_menu_wrapper" >
              <button class="lang_dropdown_menu_btn" type="button" id="lang_dropdown_menu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{lang.active}} <i class="fas fa-chevron-down"></i>
              </button>
              <div class="lang-dropdown-menu dropdown-menu" aria-labelledby="lang_dropdown_menu">
                <a *ngIf="lang.active !== 'en'" (click)="useLanguage('en')">EN</a>
                <a *ngIf="lang.active !== 'ru'" (click)="useLanguage('ru')">RU</a>
              </div>
            </div>
          </li>
          <li class="nav-item logout">
            <a class="logout_btn" (click)="logout()">{{'Header.Logout' | translate}}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</header>
