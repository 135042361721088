import {Directive, Input, ElementRef, Renderer2, OnInit} from '@angular/core';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import * as lazySizes from 'lazysizes';

@Directive({
  selector: '[LazyLoad]',
  exportAs: 'lazyload'
})
export class LazyLoadDirective implements OnInit {
  @Input() lazyload: string;
  
  public tempImage = '../../assets/images/Eclipse-0.5s-48px.gif';
  public img;
  
  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.img = this.lazyload['changingThisBreaksApplicationSecurity'];
    this.initLazyLoading();
    this.setAttributes();
  }
  
  public initLazyLoading() {
    if (lazySizes) {
      lazySizes.init();
    }
  }
  
  public setAttributes() {
    this.renderer.addClass(this.el.nativeElement, 'lazyload');
    this.setImgSrc();
  }
  
  setImgSrc() {
    this.renderer.setAttribute(this.el.nativeElement, 'data-src', this.img);
    this.renderer.setAttribute(this.el.nativeElement, 'src', this.tempImage);
  }
  
}
