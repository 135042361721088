import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { EnvService } from "./env.service";
// import { environment } from "../../environments/environment";

@Injectable()
export class MobileUsersService {
  
  // private API_BASE = environment.apiBase;
  USER: any;
  
  getUserData(): void {
    this.USER = JSON.parse(localStorage.getItem('currentUser'));
  }
  
  constructor(
    private env: EnvService,
    private http: HttpClient
  ) {}
  
  public getMobileUsers(): Observable<any> {
    const url = `${this.env.apiBase}/user`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id);
    return this.http.get<Object>(url, {params});
  }
  
  public getSingleMobileUser(data): Observable<any> {
    const url = `${this.env.apiBase}/user`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id)
      .append('user_id', data.id);
    return this.http.get<Object>(url, {params});
  }
  
  public updateSingleMobileUser(data): Observable<any> {
    const url = `${this.env.apiBase}/user`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id);
    return this.http.put<Object>(url, {
      'user_id': data.id,
      'name': data.name,
      'second_name': data.second_name,
      'email': data.email,
      'address': data.address,
      'additional_information': data.additional_info,
      'attributes_id': data.attribute_ids
    }, {params});
  }
  
  public getSubmittedUserDocuments(data): Observable<any> {
    const url = `${this.env.apiBase}/user/document`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id)
      .append('user_id', data.user_id);
    return this.http.get<Object>(url, {params});
  }
  
  public acceptSubmittedDocument(data): Observable<any> {
    const url = `${this.env.apiBase}/user/document`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id)
      .append('document_id', data.document_id)
      .append('status', data.status);
    return this.http.put<Object>(url, {}, {params});
  }
  
  public rejectSubmittedDocument(data): Observable<any> {
    const url = `${this.env.apiBase}/user/document`;
    this.getUserData();
    let params = new HttpParams()
      .append('userId', this.USER.user_id)
      .append('document_id', data.document_id)
      .append('status', data.status)
      .append('comment', data.comment);
    return this.http.put<Object>(url, {}, {params});
  }
}
