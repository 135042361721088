<div class="single-mobile-user-page-tpl container">
  <div class="row">
    <div class="main-content col-12">
      <div class="top-bar">
        <div class="breadcrumbs">
          <span class="breadcrumb-item">
            <a [routerLink]="['/mobile-users']">{{ 'Header.Mobile_users' | translate }}</a>
          </span>
          <span class="breadcrumb-item" *ngIf="singleMobileUser">
            {{singleMobileUser.name + ' ' + singleMobileUser.second_name}}
            <i [ngClass]="{'active': singleMobileUser.is_active === 1}" class="fas fa-circle"></i>
            <!--<img [src]="testPerriod" alt="error" appAwsTunnel class="img"/>-->
          </span>
        </div>
        <div class="controls">
          <button class="main-action-btn no-icon-btn" *ngIf="tab === 1" (click)="editMobileUserInfo()">
            <span>{{ 'Mobile_users.Edit_information' | translate }}</span>
          </button>
        </div>
      </div>
      <div class="vertical-tabs-wrapper-unified">
        <div class="sidebar vertical-tabs">
          <ul class="tab-navigation">
            <li [class.active]="tab === 1">
              <a (click)="tabHandler(1)" target="_self">
                <span class="mobile-user-tab-title">{{ 'Mobile_users.General_information' | translate }}</span>
              </a>
            </li>
            <li [class.active]="tab === 2">
              <a (click)="tabHandler(2)" target="_self">
                <span class="mobile-user-time-tracking-tab-title">{{ 'Mobile_users.Mobile_user_time_tracking_tab' | translate }}</span>
              </a>
            </li>
            <li [class.active]="tab === 3">
              <a (click)="tabHandler(3)" target="_self">
                <span class="mobile-user-documents-tab-title">
                  <span>{{ 'Mobile_users.Mobile_user_documents_tab' | translate }}</span>
                  <img *ngIf="showLoading" class="documents-loading-indicator" src="../../../assets/images/Ellipsis-2s-200px.gif" alt="">
                </span>
              </a>
            </li>
          </ul>
        </div>
        <div class="tab-content">
          <div id="mobile-user-data" class="tab" [class.active]="tab === 1">
            <div class="mobile-user-info-wrapper" *ngIf="singleMobileUser">
              <table class="mobile-user-info-table">
                <tbody>
                <tr>
                  <td class="td-title">{{ 'Mobile_users.Name' | translate }}:</td>
                  <td class="td-content">{{singleMobileUser.name}}</td>
                </tr>
                <tr>
                  <td class="td-title">{{ 'Mobile_users.Second_name' | translate }}:</td>
                  <td class="td-content">{{singleMobileUser.second_name || '-'}}</td>
                </tr>
                <tr>
                  <td class="td-title">{{ 'Mobile_users.User_phone_number' | translate }}:</td>
                  <td class="td-content">{{singleMobileUser.phone}}</td>
                </tr>
                <tr>
                  <td class="td-title">{{ 'Mobile_users.User_email' | translate }}:</td>
                  <td class="td-content">{{singleMobileUser.email || '-'}}</td>
                </tr>
                <tr>
                  <td class="td-title">{{ 'Mobile_users.User_address' | translate }}:</td>
                  <td class="td-content">{{singleMobileUser.address || '-'}}</td>
                </tr>
                <tr class="attributes_row">
                  <td class="td-title">{{ 'Mobile_users.Attributes' | translate }}:</td>
                  <td class="td-content">
                    <span *ngIf="!singleMobileUser.attributes.length">-</span>
                    <span *ngFor="let i of singleMobileUser.attributes">
                      {{i.description}}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="td-title">{{ 'Mobile_users.Additional_information' | translate }}:</td>
                  <td class="td-content pre-line-data">{{singleMobileUser.additional_information || '-'}}</td>
                </tr>
                </tbody>
              </table>

              <div class="mobile-user-actions">
                <div *permission="['operator']" class="mobile-user-assigment-wrapper">
                  <div class="current_assigned_manager_wrapper">
                    <div class="current_assigned_manager_data">
                      <p>{{'Mobile_users.Current_manager' | translate}}:</p>
                      <p [ngClass]="{'no_manager_assiged': !singleMobileUser.work_manager.name}">{{singleMobileUser.work_manager.name || 'Mobile_users.No_manager_assigned_yet' | translate}}</p>
                    </div>
                    <button *ngIf="singleMobileUser.is_manager_assigned" class="main-action-btn no-icon-btn" type="button" (click)="singleMobileUser.is_manager_assigned = false">
                      <span>{{ 'Mobile_users.Reassign_manager_btn' | translate }}</span>
                    </button>
                  </div>
                  <form *ngIf="!singleMobileUser.is_manager_assigned" class="assign_manager" name="assign_manager" id="assign_manager" [formGroup]="assignManagerForm"  #f="ngForm" (ngSubmit)="assignManagerForm.valid && assignManager()" novalidate>
                    <div class="form-group">
                      <div class="input-wrapper tax_advisor_manager">
                        <!--<label>{{ 'Settings.User_attributes.Select_document_types' | translate}}</label>-->
                        <ng-select [items]="taxAdvisorManagers"
                                   bindLabel="name"
                                   bindValue="id"
                                   groupBy="tax_advisers_name"
                                   [multiple]="false"
                                   [notFoundText]="'Mobile_users.No_managers_found' | translate"
                                   [placeholder]="'Mobile_users.Select_manager_placeholder' | translate"
                                   [closeOnSelect]="true"
                                   [hideSelected]="false"
                                   [selectableGroup]="false"
                                   formControlName="taxAdvisorManager">
                          <ng-template ng-optgroup-tmp let-item="item">
                            {{item.tax_advisers_name}}
                          </ng-template>
                          <ng-template ng-option-tmp let-item="item">
                            {{item.name}}
                          </ng-template>
                        </ng-select>
                      </div>
                    </div>
                    <button class="main-action-btn no-icon-btn" type="submit">
                      <span>{{ 'Mobile_users.Assign_manager_btn' | translate }}</span>
                    </button>
                  </form>
                </div>
                <div class="mobile-user-working-status-wrapper">

                  <div class="current_working_status_wrapper">
                    <div class="current_working_status_data">
                      <p>{{'Mobile_users.Current_working_status' | translate}}:</p>
                      <p>{{singleMobileUser.work_manager.status_name}}</p>
                    </div>
                    <button *permission="['manager']" [hidden]="singleMobileUser.work_manager.change_status" class="main-action-btn no-icon-btn" type="button" (click)="singleMobileUser.work_manager.change_status = true">
                      <span>{{ 'Mobile_users.Change_working_status_btn' | translate }}</span>
                    </button>
                  </div>

                  <form [hidden]="!singleMobileUser.work_manager.change_status" *permission="['manager']" class="working_status" name="working_status" id="working_status" [formGroup]="workStatusForm"  #f="ngForm" (ngSubmit)="workStatusForm.valid" novalidate>
                    <div class="form-group">
                      <div class="input-wrapper working_status">
                        <ng-select [items]="work_statuses"
                                   bindLabel="name"
                                   bindValue="id"
                                   [multiple]="false"
                                   [notFoundText]="'Mobile_users.No_statuses_found' | translate"
                                   [placeholder]="'Mobile_users.Select_working_status_placeholder' | translate"
                                   [closeOnSelect]="true"
                                   [hideSelected]="false"
                                   [selectableGroup]="false"
                                   (change)="changeWorkingStatus()"
                                   formControlName="workingStatus">
                        </ng-select>
                      </div>
                    </div>
                    <!--<button class="main-action-btn no-icon-btn" type="submit">
                      <span>{{ 'Mobile_users.Submit_change_working_status_btn' | translate }}</span>
                    </button>-->
                  </form>

                </div>
              </div>

            </div>
          </div>
          <div id="mobile-user-tracking-time" class="tab" [class.active]="tab === 2">
            <div class="mobile-user-time-tracking-table-wrapper" *ngIf="singleManagerTimeLogged">
              <div class="time-tracking-actions">
                <p>
                  <span translate>{{'Mobile_users.Total_logged_time' | translate}}:</span>
                  <span>
                    {{totalTimeLoggedForUser}}
                  </span>
                </p>
                <button *permission="['manager']" class="modal-submit-btn no-icon-btn" (click)="openLogTimeModal(logWorkTime)">{{ 'Mobile_users.Log_time_btn' | translate }}</button>
              </div>
              <div class="datatable-wrapper">
                <div class="filters" >
                  <div class="search">
                    <label class="search-label" for="pConfig1.search">
                      <i class="fas fa-search"></i>
                    </label>
                    <input [placeholder]="'Shared.Type_to_filter' | translate" [(ngModel)]="pConfig1.search" id="pConfig1.search" name='search' class="doInput">
                  </div>
                  <div class="itemsPerPage">
                    <label>{{'Shared.Items_per_page' | translate}}:</label>
                    <div class="perPageIndicators">
                      <button [ngClass]="{'active': pConfig1.itemsPerPage === 1}" (click)="pConfig1.itemsPerPage = 1; pConfig1.pageNumber = 0">1</button>
                      <button [ngClass]="{'active': pConfig1.itemsPerPage === 5}" (click)="pConfig1.itemsPerPage = 5; pConfig1.pageNumber = 0">5</button>
                      <button [ngClass]="{'active': pConfig1.itemsPerPage === 10}" (click)="pConfig1.itemsPerPage = 10; pConfig1.pageNumber = 0">10</button>
                      <button [ngClass]="{'active': pConfig1.itemsPerPage === 20}" (click)="pConfig1.itemsPerPage = 20; pConfig1.pageNumber = 0">20</button>
                    </div>
                  </div>
                </div>
                <div class="table-wrapper">
                  <table class="table">
                    <thead [class.operator]="USER.role === 'operator'">
                    <tr>
                      <th class="manager" (click)="sortBy1('manager.name')" *permission="['operator']">
                        <span>
                          {{ 'Mobile_users.Logged_time_manager_table_header' | translate }}
                        </span>
                        <span class="sort_icons" [hidden]="pConfig1.sortField != 'manager.name'">
                          <i class="fas fa-sort-down" [class.sort_active]="pConfig1.sortField == 'manager.name' && pConfig1.reverseOrder == true"></i>
                          <i class="fas fa-sort-up" [class.sort_active]="pConfig1.sortField == 'manager.name' && pConfig1.reverseOrder == false"></i>
                        </span>
                      </th>
                      <th class="log_time" (click)="sortBy1('log_time')">
                        <span>{{'Mobile_users.Log_time_table_header' | translate}}</span>
                        <span class="sort_icons" [hidden]="pConfig1.sortField != 'log_time'">
                          <i class="fas fa-sort-down" [class.sort_active]="pConfig1.sortField == 'log_time' && pConfig1.reverseOrder == true"></i>
                          <i class="fas fa-sort-up" [class.sort_active]="pConfig1.sortField == 'log_time' && pConfig1.reverseOrder == false"></i>
                        </span>
                      </th>
                      <th class="log_date" (click)="sortBy1('log_date')">
                        <span>{{'Mobile_users.Log_date_table_header' | translate}}</span>
                        <span class="sort_icons" [hidden]="pConfig1.sortField != 'log_date'">
                            <i class="fas fa-sort-down" [class.sort_active]="pConfig1.sortField == 'log_date' && pConfig1.reverseOrder == true"></i>
                            <i class="fas fa-sort-up" [class.sort_active]="pConfig1.sortField == 'log_date' && pConfig1.reverseOrder == false"></i>
                        </span>
                      </th>
                      <th class="log_comment">
                        <span>{{'Mobile_users.Log_comment_table_header' | translate}}</span>
                      </th>
                      <th class="updated_at" (click)="sortBy1('updated_at')">
                        <span>{{'Mobile_users.Updated_at_table_header' | translate}}</span>
                        <span class="sort_icons" [hidden]="pConfig1.sortField != 'updated_at'">
                          <i class="fas fa-sort-down" [class.sort_active]="pConfig1.sortField == 'updated_at' && pConfig1.reverseOrder == true"></i>
                          <i class="fas fa-sort-up" [class.sort_active]="pConfig1.sortField == 'updated_at' && pConfig1.reverseOrder == false"></i>
                      </span>
                      </th>
                      <th *permission="['manager']" class="edit"></th>
                    </tr>
                    </thead>
                    <tbody *ngIf="singleManagerTimeLogged">
                    <tr class="hover_rows" *ngFor="let i of singleManagerTimeLogged | filter: pConfig1.search : pConfig1.search_strings | orderBy: pConfig1.sortField:pConfig1.reverseOrder | paginate: {
                    id: 'time_logged_pagination',
                    itemsPerPage: pConfig1.itemsPerPage,
                    currentPage: pConfig1.currentPage
                   }">
                      <td *permission="['operator']">
                        {{i.manager.name}}
                      </td>
                      <td>
                        {{i.log_time}}
                      </td>
                      <td>
                        {{i.log_date || '-'}}
                      </td>
                      <td class="pre-line-data">
                        {{i.comment || '-'}}
                      </td>
                      <td>
                        {{i.updated_at}}
                      </td>
                      <td *permission="['manager']" class="action_td" (click)="$event.stopPropagation(); openEditLogTimeModal(editWorkTime, i)">
                        <i class="fas fa-pencil-alt"></i>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div class="table-footer" *ngIf="singleManagerTimeLogged">
                  <div class="items-total-wrapper">
                <span translate [translateParams]="{ total_items: singleManagerTimeLogged.length }">
                  Shared.Total_items_in_table
                </span>
                  </div>
                  <pagination-controls  id="time_logged_pagination"
                                        class="table-pagination"
                                        (pageChange)="pageChange1($event)"
                                        maxSize="5"
                                        directionLinks="false"
                                        autoHide="true"
                                        responsive="false">
                  </pagination-controls>
                </div>
              </div>
            </div>
          </div>
          <div id="mobile-user-documents" class="tab" [class.active]="tab === 3">
            <div class="mobile-user-documents-wrapper" *ngIf="singleMobileUser">
              <img *ngIf="showLoading" class="documents-loading-indicator" src="../../../assets/images/Ellipsis-2s-200px.gif" alt="">
              <div class="subcategories" *ngIf="!showLoading">
                <div class="single-subcategory" *ngFor="let i of singleMobileUser.relevantSubcategories">
                  <div class="subcategory-title">
                    <i class="far fa-file-alt"></i>
                    <span>{{i.title_to_show}}</span>
                  </div>
                  <div class="no-documents-uploaded" *ngIf="!i.docs.length">
                    <img src="../../../assets/images/no_docs_yet.png" alt="no-images-uploaded">
                  </div>
                  <div class="documents-wrapper" *ngIf="i.docs.length">
                    <div class="documents" *ngFor="let q of i.docs">
                      <div class="single-document" [class.accepted]="q.status === 1" [class.rejected]="q.status === 2">
                        <div class="single-document-photos">
                          <div class="document-details">
                            <div *ngIf="q.created_at" class="document-created_at">{{q.created_at}}</div>
                            <div *ngIf="q.description" class="document-description">{{q.description}}</div>
                            <div *ngIf="q.tags !== null && q.tags.length" class="document-tags">{{q.tags}}</div>
                          </div>
                          <span class="uploaded-images" [class.rejected_document]="q.status === 2">
                              <img (click)="openImageLighbox(imageLightbox, j, q.document_items_blobs, index)" *ngFor="let j of q.document_items_blobs; let index = index;" LazyLoad [lazyload]="j">
                            </span>
                          <div class="document-rejection-comment" *ngIf="q.status === 2">
                            <div class="document-description">{{q.comment}}</div>
                          </div>
                        </div>
                        <div class="single-document-controls" *ngIf="q.status === 0">
                          <i class="fas fa-check-double accept_document" (click)="acceptDocument(q)"></i>
                          <i class="far fa-times-circle decline_document" (click)="openRejectDocumentModal(documentReject, q)"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  </div>
</div>


<ng-template #logWorkTime>
  <div class="modal-header">
    <h5 class="modal-title pull-left">
      {{ 'Mobile_users.Log_time_modal_title' | translate }}
    </h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="logTimeForm" name="logTimeForm" id="logTimeForm" [formGroup]="logWorkTimeForm"  #f="ngForm" (ngSubmit)="logWorkTimeForm.valid && logWorkTimeSubmit()" novalidate>
      <div class="form-group">
        <div class="input-wrapper time">
          <label for="time" class="no-text-label">{{'Mobile_users.Log_time_label' | translate}}</label>
          <input type="number" class="form-control" name="time" id="time" [placeholder]="'Mobile_users.Log_time_placeholder' | translate" formControlName="time" min="1"/>
        </div>
        <div class="form-control-feedback"
             *ngIf="f.submitted && !!logWorkTimeForm.controls.time.errors || !!logWorkTimeForm.controls.time.errors && (!!logWorkTimeForm.controls.time.dirty || !!logWorkTimeForm.controls.time.touched)">
          <p *ngIf="f.submitted && !!logWorkTimeForm.controls.time.errors.required || !!logWorkTimeForm.controls.time.errors.required">{{'Shared.Field_is_required' | translate}}</p>
          <p *ngIf="!!logWorkTimeForm.controls.time.errors?.min" translate [translateParams]="{ min_number_value: logWorkTimeForm.controls.time.errors.min.min }">
            Shared.Min_number_error
          </p>
        </div>
      </div>

      <div class="form-group">
        <div class="input-wrapper date">
          <label for="date" class="no-text-label">{{'Mobile_users.Log_date_label' | translate}}</label>
          <input type="text" name="date" id="date" class="form-control" [placeholder]="'Mobile_users.Log_date_placeholder' | translate" bsDatepicker [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD HH:mm', containerClass: 'theme-dark-blue' }" formControlName="date" autocomplete="off" />
        </div>
        <div class="form-control-feedback"
             *ngIf="f.submitted && !!logWorkTimeForm.controls.date.errors || !!logWorkTimeForm.controls.date.errors && (!!logWorkTimeForm.controls.date.dirty || !!logWorkTimeForm.controls.date.touched)">
          <p *ngIf="f.submitted && !!logWorkTimeForm.controls.date.errors.required || !!logWorkTimeForm.controls.date.errors.required">{{'Shared.Field_is_required' | translate}}</p>
          <p *ngIf="!!logWorkTimeForm.controls.date.errors.maxlength">{{'Shared.Field_is_too_long' | translate}}</p>
        </div>
      </div>
      <div class="form-group">
        <div class="input-wrapper log_comment">
          <label for="log_comment">{{ 'Mobile_users.Log_comment' | translate }}</label>
          <textarea class="form-control" name="log_comment" id="log_comment" formControlName="comment"></textarea>
        </div>
        <!--<div class="form-control-feedback"
             *ngIf="f.submitted && !!editWorkTimeForm.controls.log_comment.errors || !!editWorkTimeForm.controls.log_comment.errors && (!!editWorkTimeForm.controls.log_comment.dirty || !!editWorkTimeForm.controls.log_comment.touched)">
          <p *ngIf="f.submitted && !!editWorkTimeForm.controls.log_comment.errors.required || !!editWorkTimeForm.controls.log_comment.errors.required">{{'Shared.Field_is_required' | translate}}</p>
          <p *ngIf="!!editWorkTimeForm.controls.log_comment.errors.maxlength">{{'Shared.Field_is_too_long' | translate}}</p>
        </div>-->
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="modal-submit-btn no-icon-btn" form="logTimeForm">
      <span>{{ 'Mobile_users.Log_time_modal_submit_btn' | translate }}</span>
    </button>
    <button class="modal-cancel-btn no-icon-btn" type="button" (click)="modalRef.hide()">
      <span>{{ 'Shared.Close_modal' | translate }}</span>
    </button>
  </div>
</ng-template>

<ng-template #editWorkTime>
  <div class="modal-header">
    <h5 class="modal-title pull-left">
      {{ 'Mobile_users.Edit_log_time_modal_title' | translate }}
    </h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="editWorkTimeForm" name="editWorkTimeForm" id="editWorkTimeForm" [formGroup]="editWorkTimeForm"  #f="ngForm" (ngSubmit)="editWorkTimeForm.valid && editWorkTimeSubmit()" novalidate>
      <div class="form-group">
        <div class="input-wrapper time">
          <label for="time_edit" class="no-text-label">{{'Mobile_users.Log_time_label' | translate}}</label>
          <input type="number" class="form-control" name="time" id="time_edit" [placeholder]="'Mobile_users.Log_time_placeholder' | translate" formControlName="time" min="1"/>
        </div>
        <div class="form-control-feedback"
             *ngIf="f.submitted && !!editWorkTimeForm.controls.time.errors || !!editWorkTimeForm.controls.time.errors && (!!editWorkTimeForm.controls.time.dirty || !!editWorkTimeForm.controls.time.touched)">
          <p *ngIf="f.submitted && !!editWorkTimeForm.controls.time.errors.required || !!editWorkTimeForm.controls.time.errors.required">{{'Shared.Field_is_required' | translate}}</p>
          <p *ngIf="!!editWorkTimeForm.controls.time.errors?.min" translate [translateParams]="{ min_number_value: editWorkTimeForm.controls.time.errors.min.min }">
            Shared.Min_number_error
          </p>
        </div>
      </div>
      <div class="form-group">
        <div class="input-wrapper date">
          <label for="date_edit" class="no-text-label">{{'Mobile_users.Log_date_label' | translate}}</label>
          <input type="text" name="date" id="date_edit" class="form-control" [placeholder]="'Mobile_users.Log_date_placeholder' | translate" bsDatepicker [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD HH:mm', containerClass: 'theme-dark-blue' }" formControlName="date" autocomplete="off" />
        </div>
        <div class="form-control-feedback"
             *ngIf="f.submitted && !!editWorkTimeForm.controls.date.errors || !!editWorkTimeForm.controls.date.errors && (!!editWorkTimeForm.controls.date.dirty || !!editWorkTimeForm.controls.date.touched)">
          <p *ngIf="f.submitted && !!editWorkTimeForm.controls.date.errors.required || !!editWorkTimeForm.controls.date.errors.required">{{'Shared.Field_is_required' | translate}}</p>
          <p *ngIf="!!editWorkTimeForm.controls.date.errors.maxlength">{{'Shared.Field_is_too_long' | translate}}</p>
        </div>
      </div>
      <div class="form-group">
        <div class="input-wrapper log_comment">
          <label for="log_comment_edit">{{ 'Mobile_users.Log_comment' | translate }}</label>
          <textarea class="form-control" name="log_comment" id="log_comment_edit" formControlName="comment"></textarea>
        </div>
        <!--<div class="form-control-feedback"
             *ngIf="f.submitted && !!editWorkTimeForm.controls.log_comment.errors || !!editWorkTimeForm.controls.log_comment.errors && (!!editWorkTimeForm.controls.log_comment.dirty || !!editWorkTimeForm.controls.log_comment.touched)">
          <p *ngIf="f.submitted && !!editWorkTimeForm.controls.log_comment.errors.required || !!editWorkTimeForm.controls.log_comment.errors.required">{{'Shared.Field_is_required' | translate}}</p>
          <p *ngIf="!!editWorkTimeForm.controls.log_comment.errors.maxlength">{{'Shared.Field_is_too_long' | translate}}</p>
        </div>-->
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="modal-submit-btn no-icon-btn" form="editWorkTimeForm">
      <span>{{ 'Mobile_users.Log_time_modal_submit_btn' | translate }}</span>
    </button>
    <button class="modal-cancel-btn no-icon-btn" type="button" (click)="modalRef.hide()">
      <span>{{ 'Shared.Close_modal' | translate }}</span>
    </button>
  </div>
</ng-template>

<!--<ng-template #imageLightbox>
  <div class="modal-body">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
    <img [src]="imageToPreview">
  </div>
</ng-template>-->

<ng-template #imageLightbox>
  <div class="modal-body">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
    <carousel [(activeSlide)]="userDocumentsSlider.activeSlideIndex" [interval]="false">
      <slide *ngFor="let slide of userDocumentsSlider.images">
        <img class="{{index}}" [src]="slide" alt="image slide" style="display: block; width: 100%;">
      </slide>
    </carousel>
  </div>
</ng-template>

<ng-template #documentReject>
  <div class="modal-header">
    <h5 class="modal-title pull-left">
      {{ 'Mobile_users.Reject_document_modal_title' | translate }}
    </h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="rejectDocumentForm" name="rejectDocumentForm" id="rejectDocumentForm" [formGroup]="rejectDocumentForm"  #f="ngForm" (ngSubmit)="rejectDocumentForm.valid && rejectDocumentSubmit()" novalidate>
      <div class="form-group">
        <div class="input-wrapper reject_comment">
          <label for="reject_comment">{{ 'Mobile_users.Reject_comment' | translate }}</label>
          <textarea class="form-control" name="reject_comment" id="reject_comment" formControlName="reject_comment" required></textarea>
        </div>
        <div class="form-control-feedback"
             *ngIf="f.submitted && !!rejectDocumentForm.controls.reject_comment.errors || !!rejectDocumentForm.controls.reject_comment.errors && (!!rejectDocumentForm.controls.reject_comment.dirty || !!rejectDocumentForm.controls.reject_comment.touched)">
          <p *ngIf="f.submitted && !!rejectDocumentForm.controls.reject_comment.errors.required || !!rejectDocumentForm.controls.reject_comment.errors.required">{{'Shared.Field_is_required' | translate}}</p>
          <p *ngIf="!!rejectDocumentForm.controls.reject_comment.errors.maxlength">{{'Shared.Field_is_too_long' | translate}}</p>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="modal-submit-btn no-icon-btn" form="rejectDocumentForm">
      <span>{{ 'Mobile_users.Reject_document_modal_submit_btn' | translate }}</span>
    </button>
    <button class="modal-cancel-btn no-icon-btn" type="button" (click)="modalRef.hide()">
      <span>{{ 'Shared.Close_modal' | translate }}</span>
    </button>
  </div>
</ng-template>
