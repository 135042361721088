<div class="outlet-wrapper user-attributes-component-wrapper">

  <div class="outlet-header">
    <span class="user_attr_title">{{ 'Settings.User_attributes.Page_title' | translate}}</span>
    <button class="main-action-btn no-icon-btn" (click)="openAddAttrsModal(newAttr)">
      <span>{{ 'Settings.User_attributes.Add_new_attr_btn' | translate}}</span>
    </button>
  </div>
  <div class="outlet-main-content">
    <div class="user-attr-table-wrapper">
      <table class="user-attr-table">
        <thead>
        <tr>
          <th class="name">{{ 'Settings.User_attributes.Name' | translate }}</th>
          <th class="edit"></th>
          <th class="delete"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let i of attributesList">
          <td>
            <div>
              {{i.description}}
              <span class="user_attribute_type">[ {{'Settings.User_attributes.' + i.attribute_type.name | translate}} ]</span>
            </div>
            <div class="attribute_documents">
              <div class="documents_wrapper" >
                <span *ngFor="let q of i.sub_categories" [class.hidden]="!q.show">
                  <span>{{q.title_to_show}}</span>
                </span>
                <p (click)="toggleCategories(i)" *ngIf="i.sub_categories.length > 3 && !i.show_all_sub" translate [translateParams]="{ count: i.sub_categories.length - 3 }">
                  Settings.User_attributes.Show_more
                </p>
                <p class="show_less" (click)="toggleCategories(i)" *ngIf="i.sub_categories.length > 3 && i.show_all_sub" translate [translateParams]="{ count: i.sub_categories.length - 3 }">
                  Settings.User_attributes.Show_less
                </p>
              </div>
            </div>
          </td>
          <td class="edit_btn" (click)="opeEditAttrsModal(editAttr, i)">
            <i class="fas fa-edit"></i>
          </td>
          <td class="delete_btn" (click)="deleteAttribute(i)">
            <i class="fas fa-trash"></i>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #newAttr>
  <div class="modal-header">
    <h5 class="modal-title pull-left">
      {{ 'Settings.User_attributes.Add_new_attr_modal_title' | translate }}
    </h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="newAttr" name="newAttr" id="newAttr" [formGroup]="addAttributesForm"  #f="ngForm" (ngSubmit)="addAttributesForm.valid && addNewAttribute()" novalidate>
      <div class="form-group">
        <div class="input-wrapper name">
          <label for="name" class="no-text-label"></label>
          <input class="form-control" name="name" id="name" [placeholder]="'Settings.User_attributes.Attribute_name_placeholder' | translate" formControlName="name"/>
        </div>
        <div class="form-control-feedback"
             *ngIf="f.submitted && !!addAttributesForm.controls.name.errors || !!addAttributesForm.controls.name.errors && (!!addAttributesForm.controls.name.dirty || !!addAttributesForm.controls.name.touched)">
          <p *ngIf="f.submitted && !!addAttributesForm.controls.name.errors.required || !!addAttributesForm.controls.name.errors.required">{{'Shared.Field_is_required' | translate}}</p>
          <p *ngIf="!!addAttributesForm.controls.name.errors.maxlength">{{'Shared.Field_is_too_long' | translate}}</p>
        </div>
      </div>
      <div class="form-group">
        <div class="input-wrapper type">
          <label for="type" class="no-text-label"></label>
          <select class="form-control" name="type" id="type" formControlName="type">
            <option *ngFor="let i of attributeTypes" [value]="i.id" translate>
              {{'Settings.User_attributes.' + i.name}}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group">
        <div class="input-wrapper attrs">
          <!--<label>{{ 'Settings.User_attributes.Select_document_types' | translate}}</label>-->
          <ng-select [items]="document_types"
                     bindLabel="title_to_show"
                     bindValue="id"
                     #select1
                     groupBy="category_title_to_show"
                     appendTo=".input-wrapper.attrs"
                     [multiple]="true"
                     [notFoundText]="'Settings.User_attributes.No_documents_found' | translate"
                     [placeholder]="'Settings.User_attributes.Select_document_types_placeholder' | translate"
                     [closeOnSelect]="false"
                     [hideSelected]="false"
                     [searchable]="true"
                     [selectableGroup]="true"
                     [selectableGroupAsModel]="false"
                     formControlName="document_types_input">
            <ng-template ng-optgroup-tmp let-item="item">
              <span>
                {{item.category_title_to_show}}
                <span class="select_all_group_icon">
                  {{'Settings.User_attributes.Select_whole_group_of_documents' | translate}}
                </span>
                <span class="deselect_all_group_icon">
                  {{'Settings.User_attributes.Deselect_whole_group_of_documents' | translate}}
                </span>
              </span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              {{item.title_to_show}}
            </ng-template>
            <ng-template ng-footer-tmp>
              <button class="main-action-btn no-icon-btn" (click)="closeSelect(select1)">
                {{'Settings.User_attributes.Confirm_selected_documents_btn' | translate}}
              </button>
            </ng-template>
          </ng-select>
          <div class="ng-select-controls" [class.hide_clear_all]="!addAttributesForm.value.document_types_input.length">
            <button class="cancel-form-btn no-icon-btn clear_all_documents_btn" type="button" (click)="clearAll1()">
              {{'Settings.User_attributes.Clear_all_documents_btn' | translate}}
            </button>
            <button class="main-action-btn no-icon-btn add_more_documents_btn" type="button" (click)="openSelect(select1)">
              {{'Settings.User_attributes.Add_more_documents_btn' | translate}}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="modal-submit-btn no-icon-btn" form="newAttr">
      <span>{{ 'Shared.Submit_modal' | translate }}</span>
    </button>
    <button class="modal-cancel-btn no-icon-btn" type="button" (click)="modalRef.hide()">
      <span>{{ 'Shared.Close_modal' | translate }}</span>
    </button>
  </div>
</ng-template>

<ng-template #editAttr>
  <div class="modal-header">
    <h5 class="modal-title pull-left">
      {{ 'Settings.User_attributes.Edit_attr_modal_title' | translate }}
    </h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="editAttr" name="editAttr" id="editAttr" [formGroup]="editAttributesForm"  #f="ngForm" (ngSubmit)="editAttributesForm.valid && editAttribute(itemToEdit)" novalidate>

      <div class="form-group">
        <div class="input-wrapper name">
          <label for="name_edit" class="no-text-label"></label>
          <input class="form-control" name="name" id="name_edit" [placeholder]="'Settings.User_attributes.Attribute_name_placeholder' | translate" formControlName="name"/>
        </div>
        <div class="form-control-feedback"
             *ngIf="f.submitted && !!editAttributesForm.controls.name.errors || !!editAttributesForm.controls.name.errors && (!!editAttributesForm.controls.name.dirty || !!editAttributesForm.controls.name.touched)">
          <p *ngIf="f.submitted && !!editAttributesForm.controls.name.errors.required || !!editAttributesForm.controls.name.errors.required">{{'Shared.Field_is_required' | translate}}</p>
          <p *ngIf="!!editAttributesForm.controls.name.errors.maxlength">{{'Shared.Field_is_too_long' | translate}}</p>
        </div>
      </div>
      <div class="form-group">
        <div class="input-wrapper type">
          <label for="type_edit" class="no-text-label"></label>
          <select class="form-control" name="type_edit" id="type_edit" formControlName="type">
            <option *ngFor="let i of attributeTypes" [value]="i.id" translate>{{'Settings.User_attributes.' + i.name}}</option>
          </select>
        </div>
      </div>

      <div class="form-group">
        <div class="input-wrapper attrs">
          <!--<label>{{ 'Settings.User_attributes.Select_document_types' | translate}}</label>-->
          <ng-select [items]="document_types"
                     bindLabel="title_to_show"
                     bindValue="id"
                     #select
                     groupBy="category_title_to_show"
                     [multiple]="true"
                     appendTo=".input-wrapper.attrs"
                     [notFoundText]="'Settings.User_attributes.No_documents_found' | translate"
                     [placeholder]="'Settings.User_attributes.Select_document_types_placeholder' | translate"
                     [closeOnSelect]="false"
                     [hideSelected]="false"
                     [searchable]="true"
                     [selectableGroup]="true"
                     [selectableGroupAsModel]="false"
                     formControlName="document_types_input">
            <ng-template ng-optgroup-tmp let-item="item" >
              <span>
                {{item.category_title_to_show}}
                <span class="select_all_group_icon">{{'Settings.User_attributes.Select_whole_group_of_documents' | translate}}</span>
                <span class="deselect_all_group_icon">{{'Settings.User_attributes.Deselect_whole_group_of_documents' | translate}}</span>
              </span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              {{item.title_to_show}}
            </ng-template>
            <ng-template ng-footer-tmp>
              <button class="main-action-btn no-icon-btn" (click)="closeSelect(select)">
                {{'Settings.User_attributes.Confirm_selected_documents_btn' | translate}}
              </button>
            </ng-template>
          </ng-select>
          <div class="ng-select-controls" [class.hide_clear_all]="!editAttributesForm.value.document_types_input.length">
            <button class="cancel-form-btn no-icon-btn clear_all_documents_btn" type="button" (click)="clearAll()">
              {{'Settings.User_attributes.Clear_all_documents_btn' | translate}}
            </button>
            <button class="main-action-btn no-icon-btn add_more_documents_btn" type="button" (click)="openSelect(select)">
              {{'Settings.User_attributes.Add_more_documents_btn' | translate}}
            </button>
          </div>
        </div>
      </div>

    </form>
  </div>
  <div class="modal-footer">
    <button class="modal-submit-btn no-icon-btn" form="editAttr">
      <span>{{ 'Shared.Submit_modal' | translate }}</span>
    </button>
    <button class="modal-cancel-btn no-icon-btn" type="button" (click)="modalRef.hide()">
      <span>{{ 'Shared.Close_modal' | translate }}</span>
    </button>
  </div>
</ng-template>
