<div class="outlet-wrapper faq-component-wrapper">

  <div class="outlet-header">
    <span class="faq_title">{{ 'Settings.FAQ.Title' | translate}}</span>
    <button (click)="openNewFaqModal(newFAQ)" class="main-action-btn no-icon-btn">
      <span>{{ 'Settings.FAQ.Add_new_faq_btn' | translate}}</span>
    </button>
  </div>
  <div class="outlet-main-content">

    <div class="registered_languages_filter">
      <div (click)="activeLang = i.language_code" [class.active]="activeLang === i.language_code" *ngFor="let i of registeredLangs">
        <span class="lang_code">{{i.name}}</span>
      </div>
    </div>

    <div class="qa_table_wrapper" *ngFor="let i of registeredLangs">
      <div class="faq_wrapper" *ngIf="activeLang === i.language_code">
        <div class="faq_q_a" *ngFor="let t of i.faqs; let idx = index">
          <form class="faqEditForm" name="faqEditForm" id="faqEditForm" #f="ngForm" (ngSubmit)="f.valid && editFaq(t, i)" novalidate>
            <div class="faq_data">

              <span class="faq_id">#{{idx + 1}}</span>

              <span *ngIf="!t.edit" class="question">Q: {{t.question}}</span>
              <span *ngIf="!t.edit" class="answer">A: {{t.answer}}</span>

              <div *ngIf="t.edit" class="form-group">
                <div class="input-wrapper question">
                  <label for="question" class="no-text-label"></label>
                  <textarea [class.error_required]="f.submitted && question.invalid && (question.dirty || question.touched)" class="question_textarea" name="question" id="question" [placeholder]="'Settings.FAQ.Question_placeholder' | translate" [(ngModel)]="t.question" #question="ngModel" [required]="i.language_code === 'EN'"></textarea>
                </div>
              </div>
              <div *ngIf="t.edit" class="form-group">
                <div class="input-wrapper answer">
                  <label for="answer" class="no-text-label"></label>
                  <textarea [class.error_required]="f.submitted && answer.invalid && (answer.dirty || answer.touched)" class="answer_textarea" name="answer" id="answer" [placeholder]="'Settings.FAQ.Answer_placeholder' | translate" #answer="ngModel" [(ngModel)]="t.answer" [required]="i.language_code === 'EN'"></textarea>
                </div>
              </div>

            </div>
            <div class="faq_controls">
              <button *ngIf="t.edit" type="submit" class="submit"><i class="fas fa-check-double"></i></button>
              <button *ngIf="!t.edit" type="reset" class="edit" (click)="t.edit = !t.edit"><i class="far fa-edit"></i></button>
              <button *ngIf="!t.edit" type="button" class="delete" (click)="deleteFaq(t)"><i class="far fa-trash-alt "></i></button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #newFAQ>
  <div class="modal-header">
    <h5 class="modal-title pull-left">{{ 'Settings.FAQ.New_faq_modal_title' | translate }}</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="newFaqForm" name="newFaqForm" id="newFaqForm" (ngSubmit)="f.form.valid && addNewFaq(registeredLangs)" #f="ngForm" novalidate>

      <div class="form-group" [class.error_required]="f.submitted && f.form.invalid && i.language_code === 'EN'" *ngFor="let i of registeredLangs">
        <div class="language_indicator">
          {{i.name}}
          <span *ngIf="i.language_code === 'EN'" class="required_asterisk">*</span>
        </div>
        <div class="input-wrapper new_question">
          <label for="new_question" class="no-text-label">Q:</label>
          <textarea [class.error_required]="f.submitted && new_question.invalid && (new_question.dirty || new_question.touched)" class="new_question_textarea" name="new_question" id="new_question" [placeholder]="'Settings.FAQ.Question_placeholder' | translate" [(ngModel)]="i.new_faq.question" #new_question="ngModel" [required]="i.language_code === 'EN'"></textarea>
        </div>
        <div class="input-wrapper new_answer">
          <label for="new_answer" class="no-text-label">A:</label>
          <textarea [class.error_required]="f.submitted && new_answer.invalid && (new_answer.dirty || new_answer.touched)" class="answer_textarea" name="new_answer" id="new_answer" [placeholder]="'Settings.FAQ.Answer_placeholder' | translate" #new_answer="ngModel" [(ngModel)]="i.new_faq.answer" [required]="i.language_code === 'EN'"></textarea>
        </div>
      </div>

    </form>
  </div>
  <div class="modal-footer">
    <button class="modal-submit-btn no-icon-btn" form="newFaqForm">
      <span>{{ 'Shared.Submit_modal' | translate }}</span>
    </button>
    <button class="modal-cancel-btn no-icon-btn" type="button" (click)="modalRef.hide()">
      <span>{{ 'Shared.Close_modal' | translate }}</span>
    </button>
  </div>
</ng-template>
