import { Component } from "@angular/core";
import { Node } from "../../_models/index";
import { DecisionTreeService, AlertService, SettingsService } from '../../_services/index';

@Component({
  selector: 'app-decision-tree-visualization',
  templateUrl: './decision-tree-visualization.component.html',
  styleUrls: ['./decision-tree-visualization.component.less']
})
export class DecisionTreeVisualizationComponent {
  treeWasChanged = false;
  unique_id = 1;
  QAList: Node;
  availableAttributes: any;
  
  constructor(
    public _AlertService: AlertService,
    public _DecisionTreeService: DecisionTreeService,
    public _SettingsService: SettingsService
  ) {
    this.getAttributes();
    this.getTree();
  }
  
  public changesWereMade(val: boolean) {
    this.treeWasChanged = val;
  }
  
  public getAttributes(): void {
    this._SettingsService.getAttributes()
      .subscribe(res => {
        this.availableAttributes = res.result;
      }, err => {
        console.log(err);
      });
  }
  public getTree(cancelingChanges?: string): void {
    this._DecisionTreeService.getDecisionTree()
      .subscribe(res => {
        this.QAList = res.result;
        this.getUniqueId(this.QAList);
        // console.log(this.QAList);
        if (cancelingChanges) {
          this._AlertService.success('Alert.You_successfully_reverted_all_the_latest_changes');
          this.changesWereMade(false);
        }
      }, err => {
        console.log(err);
      });
  }
  
  public getUniqueId(QAList): void {
    let t = this.unique_id;
    function rq(q) {
      for (let i = 0; i < q.children.length; i++) {
        if (q.children[i].id > t) {
          t = q.children[i].id;
        }
        if (q.children[i].children.length > 0) {
          rq(q.children[i]);
        }
      }
    }
    for (let i = 0; i < QAList.children.length; i++) {
      if (t < QAList.children[i].id) {
        t = QAList.children[i].id;
      }
      rq(QAList.children[i]);
    }
    localStorage.setItem('latest_id', t.toString());
  }
  
  public deleteNode(QAList, item?): void {
    function rq(q) {
      for (let i = 0; i < q.children.length; i++) {
        if (item.parent_id === q.children[i].id) {
          for (let w = 0; w < q.children[i].children.length; w++) {
            if (q.children[i].children[w].id === item.id) {
              let index = q.children[i].children.indexOf(q.children[i].children[w]);
              if (index !== -1) {
                q.children[i].children.splice(index, 1);
              }
            }
          }
          break;
        }
        if (q.children[i].children.length > 0) {
          rq(q.children[i]);
        }
      }
    }
    for (let i = 0; i < QAList.children.length; i++) {
      if (this.unique_id < QAList.children[i].id) {
        this.unique_id = QAList.children[i].id;
      }
      if (item.parent_id === QAList.children[i].id) {
        let index = QAList.children[i].children.indexOf(item);
        if (index !== -1) {
          QAList.children[i].children.splice(index, 1);
        }
        break;
      }
      if (item.parent_id === 1) {
        let index1 = QAList.children.indexOf(item);
        if (index1 !== -1) {
          QAList.children.splice(index1, 1);
        }
        break;
      }
      rq(QAList.children[i]);
    }
  }
  
  /* IS USED TO CLEAN UP THE UNFINISHED BRANCHES WITH 'NEXT_QUESTION_ID' */
  public recursiveCycle(QAList): void {
    function rq(q) {
      for (let i = 0; i < q.children.length; i++) {
        if (q.children[i].children.length === 0) {
          q.children[i].next_question_id = null;
        }
        if (q.children[i].children.length > 0) {
          rq(q.children[i]);
        }
      }
    }
    for (let i = 0; i < QAList.children.length; i++) {
      rq(QAList.children[i]);
    }
  }
  
  public receiveMessage($event) {
    this.deleteNode(this.QAList, $event);
  }
  public receiveMessageAboutChanges() {
    this.changesWereMade(true);
  }
  
  public importDecisionTree(): void {
    this.recursiveCycle(this.QAList);
    this._DecisionTreeService.importDecisionTree(this.QAList)
      .subscribe(res => {
        console.log(res);
        this._AlertService.success('Alert.You_successfully_saved_all_changes');
        this.changesWereMade(false);
      }, err => {
        console.log(err);
      });
  }

}
