import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { Node, Nodes } from '../../../_models/nodes';
import { BsModalService } from 'ngx-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import { NgSelectComponent } from "@ng-select/ng-select";

@Component({
  selector: 'app-node',
  templateUrl: './node.component.html',
  styleUrls: ['./node.component.less']
})
export class NodeComponent implements OnInit {
  editNodeForm: FormGroup;
  addNodeForm: FormGroup;
  modalRef: BsModalRef;
  
  @Input() nodes: Node;
  @Input() hasParent: boolean;
  @Input() attributes: any;
  @Output() nodeDeleteEvent = new EventEmitter<string>();
  @Output() importTreeEvent = new EventEmitter<string>();
  @Output() changesWereMade = new EventEmitter<string>();
  
  constructor(
    private modalService: BsModalService,
    private fb: FormBuilder,
    private translate: TranslateService,
  ) { }
  ngOnInit() { }
  
  openEditNodeModal(template: TemplateRef<any>, node) {
    console.log(node);
    this.createEditNodeForm(node);
    this.modalRef = this.modalService.show(
      template,
      Object.assign( { 'class': 'editNode' })
    );
  }
  openAddNodeModal(template: TemplateRef<any>, node) {
    console.log(node);
    this.createAddNodeForm(node);
    this.modalRef = this.modalService.show(
      template,
      Object.assign( { 'class': 'addNode' })
    );
  }
  
  createEditNodeForm(node: Node): void {
    this.editNodeForm = this.fb.group({
      name1: [node.name, [Validators.required]],
      name2: [node.name2],
      tree_end1: [node.tree_end],
      attributes1: [node.attributes],
      registration1: [node.registration],
      multi_answer1: [node.multi_answer]
    });
  }
  createAddNodeForm(node: Node): void {
    this.addNodeForm = this.fb.group({
      name1: [null, [Validators.required]],
      name2: [null],
      tree_end2: [node.tree_end],
      attributes2: [node.attributes],
      registration2: [node.registration],
      multi_answer2: [node.multi_answer]
    });
  }
  
  public editNode(node): void {
    node.name = this.editNodeForm.value.name1;
    node.name2 = this.editNodeForm.value.name2;
    node.tree_end = this.editNodeForm.value.tree_end1;
    node.attributes = this.editNodeForm.value.attributes1;
    node.registration = this.editNodeForm.value.registration1;
    node.multi_answer = this.editNodeForm.value.multi_answer1;
    this.modalRef.hide();
    this.changesWereMade.emit();
    console.log(node);
  }
  public addNode(node): void {
    let t = parseInt(localStorage.getItem('latest_id'), 10);
    let temp_id = t + 1;
    localStorage.setItem('latest_id', temp_id.toString());
    if (node.type === 'question') {
      this.nodes.children.push(new Nodes(temp_id, this.addNodeForm.value.name1, this.addNodeForm.value.name2, node.id, null, false, 'answer', this.addNodeForm.value.registration2, this.addNodeForm.value.tree_end2, this.addNodeForm.value.multi_answer2, this.addNodeForm.value.attributes2, []));
    } else {
      this.nodes.children.push(new Nodes(temp_id, this.addNodeForm.value.name1, this.addNodeForm.value.name2, node.id, null, false, 'question', false, this.addNodeForm.value.tree_end2, this.addNodeForm.value.multi_answer2, [], []));
      this.nodes['next_question_id'] = temp_id;
    }
    this.modalRef.hide();
    this.changesWereMade.emit('true');
  }
  public deleteNode(i): void {
    let q = confirm('Are you sure you want to delete this?');
    if (q) {
      this.nodeDeleteEvent.emit(i);
      this.changesWereMade.emit();
    }
  }
  
  closeSelect(select: NgSelectComponent) {
    select.close();
  }
  openSelect(select: NgSelectComponent) {
    select.open();
  }
  clearAll() {
    let translated_msg;
    this.translate.get('Alert.Are_you_sure_question')
      .subscribe((res: Object) => {
        translated_msg = res;
        let q = confirm(translated_msg);
        if (q) {
          this.editNodeForm.value['attributes1'] = [];
          this.editNodeForm.controls['attributes1'].setValue(this.editNodeForm.value['attributes1']);
        }
      });
  }
  clearAll1() {
    let translated_msg;
    this.translate.get('Alert.Are_you_sure_question')
      .subscribe((res: Object) => {
        translated_msg = res;
        let q = confirm(translated_msg);
        if (q) {
          this.addNodeForm.value['attributes2'] = [];
          this.addNodeForm.controls['attributes2'].setValue(this.addNodeForm.value['attributes2']);
        }
      });
  }
  
}
