import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { CategoriesListComponent } from "./categories/categories.component";
import { CreateCategoryComponent } from "./categories/create/create-category.component";
import { CreateSubcategoryComponent } from "./categories/create/create-subcategory.component";
import { EditCategoryComponent } from "./categories/edit/edit-category.component";
import { EditSubcategoryComponent } from "./categories/edit/edit-subcategory.component";
import { LoginComponent } from "./login/login.component";
import { AuthGuard } from "./_guards/index";

import { TaxAdvisorsComponent } from "./taxadvisors/taxadvisors.component";
import { SingleTaxAdvisorComponent } from "./taxadvisors/single-taxadvisor/single-taxadvisor.component";
import { SingleTaxadvisorsManagerComponent } from "./taxadvisors/single-taxadvisors-manager/single-taxadvisors-manager.component";
import { CreateTaxAdvisorComponent } from "./taxadvisors/create/create-taxadvisor.component";
import { CreateTaxAdvisorsManagerComponent } from "./taxadvisors/create/create-taxadvisors-manager.component";
import { EditTaxAdvisorComponent } from "./taxadvisors/edit/edit-taxadvisor.component";
import { EditTaxAdvisorsManagerComponent } from "./taxadvisors/edit/edit-taxadvisors-manager.component";
import { SettingsComponent } from "./settings/settings.component";
import { LanguageComponent } from "./settings/language/language.component";
import { UserAttributesComponent } from "./settings/user-attributes/user-attributes.component";
import { FaqComponent } from "./settings/faq/faq.component";
import { MobileUsersComponent } from "./mobile-users/mobile-users.component";
import { SingleMobileUserComponent } from "./mobile-users/single-mobile-user/single-mobile-user.component";
import { EditMobileUserComponent } from "./mobile-users/edit-mobile-user/edit-mobile-user.component";
import { DecisionTreeComponent } from "./decision-tree/decision-tree.component";
import { DecisionTreeVisualizationComponent } from "./decision-tree/decision-tree-visualization/decision-tree-visualization.component";
import { DecisionTreeTableComponent } from "./decision-tree/decision-tree-table/decision-tree-table.component";

const routes: Routes = [
  { path: '', component: CategoriesListComponent, canActivate: [AuthGuard], data: { role: 'operator' } },
  { path: 'login', component: LoginComponent },
  { path: 'category/new', component: CreateCategoryComponent, canActivate: [AuthGuard], data: { role: 'operator' } },
  { path: ':id/subcategory/new', component: CreateSubcategoryComponent, canActivate: [AuthGuard], data: { role: 'operator' } },
  { path: 'category/:id/edit', component: EditCategoryComponent, canActivate: [AuthGuard], data: { role: 'operator' } },
  { path: 'subcategory/:id/edit', component: EditSubcategoryComponent, canActivate: [AuthGuard], data: { role: 'operator' } },
  { path: 'taxadvisors', component: TaxAdvisorsComponent, canActivate: [AuthGuard], data: { role: 'operator' } },
  { path: 'taxadvisors/:id', component: SingleTaxAdvisorComponent, canActivate: [AuthGuard], pathMatch: 'full', data: { role: 'operator' } },
  { path: 'taxadvisor/new', component: CreateTaxAdvisorComponent, canActivate: [AuthGuard], pathMatch: 'full', data: { role: 'operator' } },
  { path: 'taxadvisors/:id/edit', component: EditTaxAdvisorComponent, canActivate: [AuthGuard], pathMatch: 'full', data: { role: 'operator' } },
  { path: 'taxadvisors/:id/manager/new', component: CreateTaxAdvisorsManagerComponent, canActivate: [AuthGuard], pathMatch: 'full', data: { role: 'operator' } },
  { path: 'taxadvisors/:id/manager/:manager_id', component: SingleTaxadvisorsManagerComponent, canActivate: [AuthGuard], pathMatch: 'full', data: { role: 'operator' } },
  { path: 'taxadvisors/:id/manager/:manager_id/edit', component: EditTaxAdvisorsManagerComponent, canActivate: [AuthGuard], pathMatch: 'full', data: { role: 'operator' } },
  { path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard],
    data: { role: 'operator' },
    children: [
      {path: '', redirectTo: 'language', pathMatch: 'full', data: { role: 'operator' }},
      {path: 'language', component: LanguageComponent, data: { role: 'operator' }},
      {path: 'user-attributes', component: UserAttributesComponent, data: { role: 'operator' }},
      {path: 'faq', component: FaqComponent, data: { role: 'operator' }}
    ] },
  { path: 'decision-tree',
    component: DecisionTreeComponent,
    canActivate: [AuthGuard],
    data: { role: 'operator' },
    children: [
      {path: 'tree-view', component: DecisionTreeVisualizationComponent, data: { role: 'operator' }},
      {path: 'table-view', component: DecisionTreeTableComponent, data: { role: 'operator' }}
    ] },
  { path: 'mobile-users', component: MobileUsersComponent, canActivate: [AuthGuard], data: { role: 'anyone' } },
  { path: 'mobile-users/:id', component: SingleMobileUserComponent, canActivate: [AuthGuard], data: { role: 'anyone' } },
  { path: 'mobile-users/:id/edit', component: EditMobileUserComponent, canActivate: [AuthGuard], data: { role: 'anyone' } },
  
  { path: '**', component: LoginComponent }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
  })],
  declarations: []
})
export class AppRoutingModule {}
